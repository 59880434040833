import React, { useState,useEffect } from "react";
import {
  useGetFaqsQuery,
  useDeleteFaqMutation,
  useUpdateFaqMutation,
} from "../../app/services/faqsApi"; // Assurez-vous d'importer le hook depuis le bon emplacement
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Box, Container, Stack, Typography } from "@mui/material";

const FaqsAdmin = () => {
  const { data: faqs, isLoading, isError } = useGetFaqsQuery();
  const [deleteFaq] = useDeleteFaqMutation();
  const [updateFaq] = useUpdateFaqMutation();

  const [selectedFaqId, setSelectedFaqId] = useState(null);
  const [responseMap, setResponseMap] = useState({});
  const [confirmationMessages, setConfirmationMessages] = useState({});
  useEffect(() => {
    // Remettre le défilement en haut de la page à chaque chargement
    window.scrollTo(0, 0);
}, []);

  const handleDelete = async (faqId) => {
    try {
      const response = await deleteFaq(faqId);
      setSelectedFaqId(null); // Réinitialiser l'identifiant de la FAQ sélectionnée après la suppression
      if (response.data && response.data.message) {
        setConfirmationMessages({
          ...confirmationMessages,
          [faqId]: response.data.message,
        });
      }
    } catch (error) {
      console.error("Erreur lors de la suppression de la FAQ :", error);
    }
  };

  const handleUpdate = async (faqId) => {
    try {
      const response1 = await updateFaq({
        id: faqId,
        updatedFaq: { response: responseMap[faqId] },
      });
      console.log(response1.data.message);
      if (response1.data.message) {
        setConfirmationMessages({
          ...confirmationMessages,
          [faqId]: response1.data.message,
        });
      }
      setSelectedFaqId(null); // Réinitialiser l'identifiant de la FAQ sélectionnée après la mise à jour
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la FAQ :", error);
    }
  };

  const handleConfirmDelete = (faqId) => {
    const isConfirmed = window.confirm(
      "Êtes-vous sûr de vouloir supprimer cette FAQ ?"
    );
    if (isConfirmed) {
      handleDelete(faqId);
    }
  };

  if (isLoading) return <div>Chargement...</div>;
  if (isError) return <div>Erreur lors du chargement des FAQs.</div>;

  // Filtrer les FAQs avec une réponse null
  const faqsWithNullResponse = faqs.filter((faq) => faq.reponse === null);

  return (
    <Container
      maxWidth="xl"
      sx={{
        backgroundColor: "#ffff8d",
        pt:0,
        pb:2,
        marginTop:0,
       // padding: 4,
      }}
    >
      <Typography variant="h5">Gestion des FAQs</Typography>
      <Typography paragraph>
        Dans cette section, vous pouvez gérer les FAQs en ajoutant des
       réponses  aux questions ou en les supprimant selon vos besoins.
      </Typography>
      <Box>
      <ul>
        {faqsWithNullResponse.map((faq) => (
          <li key={faq.id}>
            <Box
              sx={{
                border: 1,
                borderRadius: 2,
                boxShadow: 3,
                p: 2, // Espace intérieur pour le contenu
              }}
            >
              <strong>Question :</strong> {faq.question}
              <br />
              <strong>Réponse :</strong>
              <TextField
                value={responseMap[faq.id] || ""}
                onChange={(e) =>
                  setResponseMap({ ...responseMap, [faq.id]: e.target.value })
                }
                //label="Réponse"
                variant="outlined"
                size="small"
                fullWidth
              />
              <Stack direction={"row"} spacing={2} sx={{ pt: 2, pb: 2 }}>
                <Button
                  onClick={() => handleUpdate(faq.id)}
                  variant="contained"
                  color="primary"
                >
                  Modifier
                </Button>
                <Button
                  onClick={() => handleConfirmDelete(faq.id)}
                  variant="contained"
                  color="error"
                >
                  Supprimer
                </Button>
              </Stack>
              {confirmationMessages[faq.id] && (
                <Typography paragraph sx={{ color: "green" }}>
                  {confirmationMessages[faq.id]}
                </Typography>
              )}
            </Box>
            <br />
          </li>
        ))}
      </ul>
      </Box>
    </Container>
  );
};

export default FaqsAdmin;
