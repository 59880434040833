import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

import { useParams } from 'react-router-dom';
import { useGetBureauQuery } from "../../app/services/bureauApi";
import Loader from '../loader/Loader';

export default function Bureau(props) {
  
    // const {id}=useParams();

    // const id=1;
    const {
        data: bureau,
        isLoading,
        isSuccess,
        isError,
        error,
      } = useGetBureauQuery(props.id);

      if (isLoading) {
        return <Loader/>
     }
     console.log(bureau.Bureau?.nom);
  return (
    <Card sx={{ maxWidth: 345, 
    padding:4,
    marginTop:4
    }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={`https://exchange-maroc.com//images/bureaux/${bureau.Bureau?.image}`}
           //image="/images/bureaux/b1.jpg"
          alt="AFRICA EXCHANGE"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
             {bureau.Bureau?.nom}
          </Typography>
          <Typography variant="body2" color="text.secondary">
          {bureau.Bureau?.adresse}
          </Typography>
          <Typography variant="body2" color="text.secondary">
          {bureau.Bureau?.description}
          </Typography>
          <Typography variant="body2" color="text.secondary">
          {bureau.Bureau?.telephone}
          </Typography>
          <Typography variant="body2" color="text.secondary">
          {bureau.Bureau?.email}
          </Typography>

        </CardContent>
      </CardActionArea>
    </Card>
  );
}