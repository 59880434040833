import { Box, Stack, Typography, Drawer, List, ListItem, ListItemIcon, IconButton, Collapse, Tooltip } from "@mui/material";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import NotificationsBar from "../header/NotificationsBar";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import LayersIcon from '@mui/icons-material/Layers';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'; // Icone pour "en cours"
import DoneIcon from '@mui/icons-material/Done'; // Icone pour "terminées"
import FactCheckIcon from '@mui/icons-material/FactCheck'; //Icone pour validées
import EmailIcon from '@mui/icons-material/Email';
import ArticleIcon from '@mui/icons-material/Article';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

const drawerWidth = 240;
const navigationBarHeight = 64; // Hauteur de la barre de navigation principale

const MenuAdmin = () => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = React.useState(false); // État pour contrôler le mode réduit
  const [openReservations, setOpenReservations] = React.useState(false); // État pour gérer l'ouverture des sous-menus

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleCollapseToggle = () => {
    setCollapsed(!collapsed); // Bascule l'état du mode réduit
  };

  const handleReservationsClick = () => {
    setOpenReservations(!openReservations); // Ouvre/ferme les sous-menus
  };

  return (
    <Stack
      direction="column"
      justifyContent={"flex-start"}
      alignItems={"center"}
      sx={{
        backgroundColor: "white",
        width: '100vw',
        padding: 0,
      }}
    >
      <Stack direction="row" sx={{ height: '100%', width: '100%' }}>
        <Drawer
          variant="persistent"
          anchor="left"
          open
          sx={{
            width: collapsed ? 60 : drawerWidth,
            flexShrink: 0,
            marginTop: `${navigationBarHeight}px`, // Ajout d'une marge supérieure pour éviter de cacher la barre de navigation
            '& .MuiDrawer-paper': {
              width: collapsed ? 60 : drawerWidth,
              boxSizing: 'border-box',
              transition: "width 0.7s ease",
              marginTop: `${navigationBarHeight}px`,
            },
          }}
        >
          {/* Bouton pour basculer entre le mode réduit et développé */}
          <IconButton onClick={handleCollapseToggle} sx={{ margin: 1 }}>
            {collapsed ? <MenuIcon /> : <MenuOpenIcon />}
          </IconButton>

          <List
            sx={{
              '& .MuiListItem-root': {
                cursor: 'pointer',
              },
            }}
          >
            {/* Menu Réservations avec sous-menus */}
            <ListItem button onClick={handleReservationsClick}>
              <ListItemIcon><Tooltip title="Réservations">{<ShoppingCartIcon />}</Tooltip></ListItemIcon>
              {!collapsed && <Typography variant="body1">Réservation</Typography>}
              {!collapsed && (openReservations ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
            </ListItem>
            <Collapse in={openReservations} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  sx={{ pl: 4 }} // padding à gauche pour indiquer que c'est un sous-menu
                  onClick={() => handleNavigation("/admin/gestionreservation/encours")}
                >
                  <ListItemIcon><Tooltip title="Réservations en cours"><HourglassEmptyIcon /></Tooltip></ListItemIcon>
                  <Typography variant="body2">Réservations en cours</Typography>
                </ListItem>
                <ListItem
                  button
                  sx={{ pl: 4 }}
                 onClick={() => handleNavigation("/admin/gestionreservation/validee")}
                >
                  <ListItemIcon><Tooltip title="Réservations validées"><FactCheckIcon /></Tooltip></ListItemIcon>
                  <Typography variant="body2">Réservations validées</Typography>
                </ListItem>
                <ListItem
                  button
                  sx={{ pl: 4 }}
                 onClick={() => handleNavigation("/admin/gestionreservation/terminee")}
                >
                  <ListItemIcon><Tooltip title="Réservations terminée"><DoneIcon /></Tooltip></ListItemIcon>
                  <Typography variant="body2">Réservations terminée</Typography>
                </ListItem>
              </List>
            </Collapse>

            <ListItem button onClick={() => handleNavigation("/admin/deviselistedit")}>
              <ListItemIcon><Tooltip title="Devises">{<CurrencyExchangeIcon />}</Tooltip></ListItemIcon>
              {!collapsed && <Typography variant="body1">Devises</Typography>}
            </ListItem>
            <ListItem button onClick={() => handleNavigation("/admin/faqsadmin")}>
              <ListItemIcon><Tooltip title="FAQs">{<LiveHelpIcon />}</Tooltip></ListItemIcon>
              {!collapsed && <Typography variant="body1">FAQs</Typography>}
            </ListItem>
            <ListItem button onClick={() => handleNavigation("/admin/newsadmin")}>
              <ListItemIcon><Tooltip title="News">{<ArticleIcon />}</Tooltip></ListItemIcon>
              {!collapsed && <Typography variant="body1">News</Typography>}
            </ListItem>
            <ListItem button onClick={() => handleNavigation("/admin/contact")}>
              <ListItemIcon><Tooltip title="Messages">{<EmailIcon />}</Tooltip></ListItemIcon>
              {!collapsed && <Typography variant="body1">Messages</Typography>}
            </ListItem>
          </List>
        </Drawer>

        {/* Deuxième colonne : Contenu principal */}
        <Box
          sx={{
            flexGrow: 1,
            padding: 2,
            overflow: 'auto',  // Permet le défilement si le contenu dépasse la hauteur de l'écran
            transition: "margin-left 0.7s ease",
          }}
        >
          <NotificationsBar />
          <Outlet />
        </Box>
      </Stack>
    </Stack>
  );
};

export default MenuAdmin;
