import { Box } from '@mui/material'
import React from 'react'
import logo1 from '../../images/logo1.png'
import logo2 from '../../images/logo2.png'

function Logo() {
  return (
    <Box sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}>
    <img src={logo1} alt="" style={{ width: 40, height: 40 }} />
    <img src={logo2} alt="" style={{ width: 160 , height: 40}} />
  </Box>
  )
}

export default Logo
