import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom'; // Importer le composant Link
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { useGetNewsQuery, useDeleteNewsMutation } from '../../app/services/newsApi';
import CreateNewsForm from './CreateNewsForm';

function NewsAdmin() {
  const { data: news, isLoading, isError } = useGetNewsQuery();
  const [deleteNews] = useDeleteNewsMutation();
  const [isAdding, setIsAdding] = useState(false);
  const [selectedNewsId, setSelectedNewsId] = useState(null);

  useEffect(() => {
    // Remettre le défilement en haut de la page à chaque chargement
    window.scrollTo(0, 0);
}, []);

  const handleDelete = async (id) => {
    try {
      await deleteNews(id);
      setSelectedNewsId(null);
    } catch (error) {
      console.error('Erreur lors de la suppression de la news :', error);
    }
  };

  const handleConfirmDelete = (id) => {
    const isConfirmed = window.confirm('Êtes-vous sûr de vouloir supprimer cette news ?');
    if (isConfirmed) {
      handleDelete(id);
    }
  };

  const handleEdit = (id) => {
    console.log('Editer la news avec ID :', id);
  };

  const handleAddNews = () => {
    setIsAdding(true);
  };

  const handleSaveNews = () => {
    setIsAdding(false);
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        backgroundColor: "#ffff8d",
        pt:0,
        pb:2,
        marginTop:0,
       // padding: 4,
      }}
    >
      <Typography variant="h5">Gestion des news</Typography>
      <Typography paragraph>
        Dans cette section, vous pouvez gérer les news.

      </Typography>
      {isAdding && <CreateNewsForm onSave={handleSaveNews} />}
      {isLoading && <div>Chargement...</div>}
      {isError && <div>Erreur lors du chargement des actualités.</div>}
      {news && (
        <Box>
          
          <Link to="/admin/createnewsadmin"> {/* Utiliser le composant Link */}
            <Button variant="contained" color="primary">Ajouter News</Button>
          </Link>
          {news.map((item) => (
            <div key={item.id}>
              <Box
                sx={{
                  border: 1,
                  borderRadius: 2,
                  boxShadow: 3,
                  p: 2,
                }}
              >
                <Typography variant='h4'>{item.title}</Typography>
                <p>{item.description}</p>
                <Stack direction="row" spacing={2}>
                  <Button variant="contained" color="primary" onClick={() => handleEdit(item.id)}>Editer</Button>
                  <Button variant="contained" color="warning" onClick={() => handleConfirmDelete(item.id)}>Supprimer</Button>
                </Stack>
              </Box>
              <br/>
            </div>
          ))}
        </Box>
      )}
    </Container>
  );
}

export default NewsAdmin;
