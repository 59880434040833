import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useGetAllDevisesQuery } from "../../app/services/deviseApi";

function DeviseList() {
  const {
    data: devises,
  } = useGetAllDevisesQuery();

  return (
    <Box
     // maxWidth="xl"
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#212121",
        alignItems: "center", // Centrer les éléments horizontalement
        justifyContent: "center", // Centrer les éléments verticalement
        pt: { xs: 8, sm: 12 },
        pb: { xs: 8, sm: 12 },
        minHeight: '100vh', // Hauteur minimum de 100% de la page
      }}
    >
      <Typography variant="h5" align="center" color="goldenrod" gutterBottom>
        Nos cours de change de devises
      </Typography>

      <Stack
        direction={"row"}
        spacing={4}
        useFlexGap
        flexWrap="wrap"
        justifyContent="center" // Centrer les éléments enfants horizontalement
        alignItems="center" // Centrer les éléments enfants verticalement
        sx={{
          borderRadius: 2,
          padding: 2,
        }}
      >
        {devises?.map((devise) => (
          <Card
            key={devise.id}
            sx={{
              width: 320, // Taille de la carte réduite
              height: 232,
              textAlign: 'center',
              transition: 'transform 0.3s ease-in-out', // Transition pour l'effet hover
              '&:hover': {
                transform: 'scale(1.1)', // Agrandir la carte au survol
              },
            }}
          >
            <Stack direction="row" justifyContent="center" alignItems="center">
              <CardContent>
                <img
                  src={`${process.env.REACT_APP_BASE_FILE_URL}/Devise/image/${devise.image}`}
                  alt={`${devise.pays}`}
                  style={{ borderRadius: 4, width: 80 }}
                />
                <Typography variant="body1" sx={{ mt: 1 }}>
                  {devise.pays}
                </Typography>
              </CardContent>

              <CardContent>
                <Typography variant="body2" color="text.secondary" sx={{ color: 'goldenrod' }}>
                  Vous achetez
                </Typography>
                <Typography color="text.secondary">
                  <strong>
                    1 {devise.name} à {devise.prixVente} MAD
                  </strong>
                </Typography>
                <Divider />
                <Typography variant="body2" color="text.secondary" sx={{ color: 'goldenrod' }}>
                  Vous vendez
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <strong>
                    1 {devise.name} à {devise.prixAchat} MAD
                  </strong>
                </Typography>
              </CardContent>
            </Stack>
          </Card>
        ))}
      </Stack>
    </Box>
  );
}

export default DeviseList;
