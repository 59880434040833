

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const API_BASE_URL = process.env.REACT_APP_BASE_URL; 

export const newsApi = createApi({
  reducerPath: 'newsApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL,credentials: 'include', }),
  endpoints: (builder) => ({
    getNews: builder.query({
      query: () => '/news',
    }),
    getNewsById: builder.query({
      query: (id) => `/news/${id}`,
    }),
    createNews: builder.mutation({
      query: (newNews) => ({
        url: '/news',
        method: 'POST',
        body: newNews,
      }),
    }),
    updateNews: builder.mutation({
      query: ({ id, updatedNews }) => ({
        url: `/news/${id}`,
        method: 'PUT',
        body: updatedNews,
      }),
    }),
    deleteNews: builder.mutation({
      query: (id) => ({
        url: `/news/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const { useGetNewsQuery, useGetNewsByIdQuery, useCreateNewsMutation, useUpdateNewsMutation, useDeleteNewsMutation } = newsApi;
