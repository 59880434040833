import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const Copyright = () => {
  return (
    <Typography variant="body2" color="white" align="center">
      &copy; {new Date().getFullYear()} EXCHANGE.COM. Tous droits réservés.{' '}
      <Link color="inherit" href="politiqueconfidentialite">
        Politique de confidentialité
      </Link>{' '}
      |{' '}
      <Link color="inherit" href="conditions">
        Conditions d'utilisation
      </Link>
      {' '}
      |{' '}
      <Link color="inherit" href="politiquecookies">
        Politique des cookies
      </Link>
    </Typography>
  );
};

export default Copyright;
