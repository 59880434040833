import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import CalculatorAchat from "../calculator/CalculatorAchat";
import CalculatorVente from "../calculator/CalculatorVente";
import Calculator from "../calculator/Calculator";
import { useNavigate } from "react-router-dom";

const TypeOperation = () => {
  const navigate = useNavigate();
  return (
    <Stack
      // spacing={4}
      direction={{ sx: "column", sm: "column" }}
      sx={{
         
        //  padding: { xs: 2, sm: 4 },
        alignItems: "center",
        // borderRadius:2,
        //  pt: { xs: 2, sm: 4 },
        // pb: { xs: 2, sm: 4 },
      }}
    >
      <Typography variant="h5">Que souhaitez-vous faire ?</Typography>
    <Stack
      spacing={4}
      direction={{ sx: "column", sm: "row" }}
      sx={{
         backgroundColor: "black",
         padding: { xs: 2, sm: 4 },
        alignItems: "center",
        borderRadius:2,
        //  pt: { xs: 2, sm: 4 },
        // pb: { xs: 2, sm: 4 },
      }}
    >
      <Box
        sx={{
          backgroundColor: "yellow",
          alignItems: "center",
          pt: { xs: 2, sm: 4 },
          pb: { xs: 2, sm: 4 },
          pl: { xs: 2, sm: 4 },
          pr: { xs: 2, sm: 4 },
          mb: 4,
          borderRadius: 2,
          outline: "1px solid",
          outlineColor: "#BFCCD9",
        }}
      >
        <Typography variant="h5">ESPACE ACHAT</Typography>
        <Typography variant="h6">
          Voulez vous acheter une devise étrangère? cliquez sur le bouton
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "black",
            color: "primary",
          }}
          onClick={() => {
            navigate("/reservation/simulatorachat");
          }}
        >
          Je souhaite acheter une devise étrangère
        </Button>
      </Box>
      <Box
        sx={{
          backgroundColor: "yellow",
          alignItems: "center",
          pt: { xs: 2, sm: 4 },
          pb: { xs: 2, sm: 4 },
          pl: { xs: 2, sm: 4 },
          pr: { xs: 2, sm: 4 },
          mb: 4,
          borderRadius: "10px",
          outline: "1px solid",
          outlineColor: "#BFCCD9",
        }}
      >
        <Typography variant="h5">ESPACE VENTE</Typography>
        <Typography variant="h6">
          Voulez vous vendre une devise étrangère? cliquez sur le bouton
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "black",
            color: "primary",
          }}
          onClick={() => {
            navigate("/reservation/simulatorvente");
          }}
        >
          Je souhaite vendre une devise étrangère
        </Button>
      </Box>
    </Stack>
    </Stack>
  );
};

export default TypeOperation;
