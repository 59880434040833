import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const deviseApi = createApi({
  reducerPath: 'deviseApi',
  baseQuery: fetchBaseQuery({
    // baseUrl: 'http://localhost:8000/api',
    baseUrl: process.env.REACT_APP_BASE_URL,
    credentials: 'include',
  }),
  tagTypes: ["Devises"],
  keepUnusedDataFor: 60,
  endpoints: (builder) => ({
    getAllDevises: builder.query({
      query: () => ({ url: '/devises' }),
      providesTags: ['Devises'],
    }),
    getDevise: builder.query({
      query: (id) => ({ url: `devises/${id}` }),
      providesTags: ['Devises'],
    }),
    addDevise: builder.mutation({
      query: (body) => ({
        url: '/devises',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Devises'],
    }),
    deleteDevise: builder.mutation({
      query: (id) => ({
        url: `devises/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Devises'],
    }),
  
    updateDevise: builder.mutation({
      query: ({ id, devise }) => ({
        url: `devises/${id}`,
        method: 'PUT', 
        body: devise,
      }),
      invalidatesTags: ['Devises'],
    }),
  }),
});

export const {
  useGetAllDevisesQuery,
  useGetDeviseQuery,
  useAddDeviseMutation,
  useDeleteDeviseMutation,
  useUpdateDeviseMutation,
} = deviseApi;
