import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, Stack } from "@mui/material";
import { useNavigate } from 'react-router-dom';

export default function MediaControlCard() {
    const navigate = useNavigate();
    const theme = useTheme();

    return (
        <Card 
            sx={{ 
                display: "flex", 
                flexDirection: { xs: 'column', md: 'row' }, // Column layout for small screens, row for medium+
                maxWidth: '100%', 
                width: { xs: '100%', sm: '80%', md: '70%' }, // Width adjustment based on screen size
                margin: 'auto', // Center the card
            }}
        >
            <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography component="div" variant="h4">
                        Gagnez du temps et de l’argent
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        component="div"
                        sx={{ color: "text.secondary" }}
                    >
                        Préparez votre voyage en toute tranquillité ! Réservez vos devises
                        en ligne et récupérez-les dans nos locaux à Marrakech. Plus besoin
                        de faire la queue ou de chercher une bonne offre - une solution
                        simple et pratique à portée de clic !
                    </Typography>
                    <Typography variant="caption" sx={{ opacity: 0.8, mt: 2, color: 'white' }}>
                        Cliquez sur "Réservez votre devise" et découvrez les meilleures offres que nous proposons.
                    </Typography>
                </CardContent>
                <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}>
                    <Stack 
                        direction={{ xs: 'column', sm: 'row' }} 
                        alignSelf="center" 
                        spacing={1} 
                        sx={{ mt: 2 }}
                    >
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={() => navigate('/reservation')}
                        >
                            Réservez votre devise
                        </Button>
                    </Stack>
                </Box>
            </Box>
            <CardMedia
                component="img"
                sx={{ 
                    width: { xs: '100%', md: 400 }, // Full width on small screens, 400px on medium+
                    height: { xs: 200, sm: 300, md: 'auto' }, // Height adjustment based on screen size
                    objectFit: 'cover' // Ensure the image scales correctly
                }}
                image={`${process.env.REACT_APP_URL_IMG}/images/b1.jpg`}
                alt="marrakech"
            />
        </Card>
    );
}
