import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box, Button, Container, Stack, TextField } from "@mui/material";
import { useAddDeviseMutation } from "../../app/services/deviseApi";
import { useNavigate } from "react-router-dom";

const VisuallyHiddenInput = styled("input")({
  // backgroundColor:'black',
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});


function Devise() {
  const [addDevise,{data:devise1,isLoading,isError,error}]=useAddDeviseMutation();
  const navigate=useNavigate();
  const [devise,setDevise]=useState({
    name:"tset",
    pays:"tsetp",
    image:"tseti",
    prixAchat:0,
    prixVente:0,
  })

  const handleForm = (e) => {
    e.preventDefault();

    addDevise(
      devise
      // nomFamille:'salaeh',
      // nomFamille2:'koris'
    ).then((data)=>{
        console.log('mes données',data);
    });
    navigate("/admin");

  };
  const handleChange = () => {};
  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          backgroundColor: "goldenrod",
          padding: { xs: 0, sm: 2, md: 8 },
          borderRadius: 4,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",

          alignItems: "center",
          height: { xs: "50vh", sm: "50vh", md: "70vh" },
        }}
      >
        <form onSubmit={handleForm}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" spacing={2}>
            <TextField
              helperText="Entrer la devise"
              id="devise"
              label="Devise"
              // color="primary"
            />

            <TextField
              helperText="Entrer le pays"
              id="pays"
              label="pays"
              value="tttttt"
              // color="primary"
            />
            <TextField
              helperText="Entrer le prix d'achat"
              id="prixAchat"
              label="prix d'achat"
              type="number"
              // color="primary"
            />

            <TextField
              helperText="Entrer le prix de vente"
              id="prixVente"
              label="prix de vente"
              type="number"
              // color="primary"
            />

          </Stack>
          <Stack direction={"column"} spacing={2}>
          <img src='images/maroc.jpg'alt='' width='240px'/>
          <Button
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
            >
              Charger image
              <VisuallyHiddenInput type="file" />
            </Button>
          </Stack>
          </Stack>
          <Button variant="contained" type="submit">
              Enregistrer
          </Button>
        </form>
      </Box>
    </Container>
  );
}

export default Devise;
 