import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

function ChangeInfo() {
    const state = useSelector((state) => state.change.value);
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    const currentDate = month + "/" + date + "/" + year;

    return (
        <Box
            sx={{
                p: 2,
                backgroundColor: "black",
                borderRadius: 2,
                color: "yellow",
                width: '100%', // Full width for responsiveness
               // maxWidth: '600px', // Limit max width for larger screens
                margin: '0 auto', // Center the box
            }}
        >
            <Typography variant='h6' sx={{ backgroundColor: 'yellow', color: 'black', textAlign: 'center', p: 1 }}>
                Mon panier
            </Typography>
            <Stack
                direction={{ xs: 'column' }}
                spacing={1}
                sx={{
                    justifyContent: "space-between",
                    color: "yellow",
                }}
            >
                <Box>
                    <Typography variant="body2">
                        Vous {state.typeOperation === "achat" ? "vendez" : "achetez"} un montant de{" "}
                        <strong>
                            {state.typeOperation === "achat" ? state.montantToExchane + " " + state.deviseToExchange.name : state.MontantToReceive + " " + state.deviseToExchange.name}
                        </strong>
                    </Typography>
                    <Typography variant="body2">
                        contre un montant de{" "}
                        <strong>
                            {state.typeOperation === "achat" ? state.MontantToReceive + " MAD" : state.montantToExchane + " MAD"}
                        </strong>
                    </Typography>
                    <Typography variant="body2">
                        avec le taux : {state.deviseToExchange.name && 1} {state.deviseToExchange.name} ={" "}
                        {state.typeOperation === "achat" ? state.deviseToExchange.prixAchat : state.deviseToExchange.prixVente} MAD
                    </Typography>
                </Box>
                {/* Uncomment to display the current date */}
                {/* <Box>
                    <Typography paragraph="true">Le {currentDate}</Typography>
                </Box> */}
            </Stack>
        </Box>
    );
}

export default ChangeInfo;
