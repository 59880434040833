
// import { createTheme } from '@mui/material/styles';

// const theme = createTheme({
//   palette: {
//     primary: {
//       light: '#FFE57F', // Jaune clair
//       main: '#FFD700', // Jaune principal
//       dark: '#FFC400', // Jaune foncé
//       contrastText: '#000', // Texte contrastant (noir)
//     },
//     secondary: {
//       light: '#333333', // Noir clair
//       main: '#000000', // Noir principal
//       dark: '#000000', // Noir foncé
//       contrastText: '#FFD700', // Texte contrastant (jaune)
//     },
//   },
// });

// export default theme;


import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#F5B730',
      main: '#F5B730',
      dark: '#372B0F',
      contrastText: '#fff',
    },
    secondary: {
      // light: '#ff7961',
      // main: '#f44336',
      // dark: '#ba000d',
      light: '#040400',
      main: '#040400',
      dark: '#040400',
      contrastText: '#000',
    },
  },
});
export default theme;