import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Box, Stack, Typography } from "@mui/material";
import Coordonnes from "./Coordonnes";
import ClientAuth from "./ClientAuth";
import ChangeInfo from "./ChangeInfo";
import RecapRetraitInfo from "./recapRetraitInfo";
import logo1 from '../../images/logo1.png'
import { yellow } from "@mui/material/colors";

export default function ReservationClient() {
  const [option, setOption] = React.useState("invite");
  return (
    <Stack>
      <Stack direction={{ xs: "column", sm: "row-reverse" }} spacing={2}>
        <Stack direction="column" spacing={2} sx={{pt:{xs:0,md:2}, backgroundColor:"black"}}>
          <Typography variant="h5" sx={{color:'yellow', display:{xs:'none',md:'block'}}}>EXCHANGE.COM</Typography>
          <ChangeInfo />
          <RecapRetraitInfo />
        </Stack>
        <Stack direction="column" spacing={2}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              width:{ xs: "92vw", md: "56vw" },
            }}
          >
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="invite"
                name="radio-buttons-group"
                onChange={(e) => setOption(e.target.value)}
              >
                <FormControlLabel
                  value="invite"
                  control={<Radio />}
                  label="Je continue en tant qu'invité"
                />
                <FormControlLabel
                  value="client"
                  control={<Radio />}
                  label="Je m'identifie"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
          sx={{width:{ xs: "92vw", md: "56vw" },}}
          >
            {option === "invite" ? <Coordonnes /> : <ClientAuth />}
          </Box>
          
        </Stack>
      </Stack>
    </Stack>
  );
}
