import React from "react";
import { MenuItem, Select, FormControl } from "@mui/material";
import phone from "../../data/phone.json";

const InputSelect = ({ onChange }) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <FormControl  size="small">
      <Select
        defaultValue={phone[0].dial_code + ' (' + phone[0].code + ')'}
        onChange={handleChange}
        displayEmpty
      >
        {phone.map((phone) => (
          <MenuItem value={phone.dial_code + ' (' + phone.code + ')'} key={phone.code}>
            {phone.dial_code + ' (' + phone.code + ')'}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default InputSelect;
