import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import Avatar from "@mui/material/Avatar";
// import { useState } from "react";
// import { useGetAllDevisesQuery } from "../../app/services/deviseApi";
// import Loader from "../loader/Loader";
// import { Typography } from "@mui/material";
// import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  convert,
  mdifMontantToChange,
  deviseToChange,
} from "../../app/services/changeSlice";


import {
  Alert,
  Avatar,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
// import Select from "@mui/joy/Select";
import BasicSelect from "./BasicSelect";
import SyncIcon from "@mui/icons-material/Sync";
// import { GifBox } from "@mui/icons-material";
import axios from "axios";
import "./style.css";
import Loader from "../loader/Loader";
import { useGetAllDevisesQuery } from "../../app/services/deviseApi";
import {addBureau} from "../../app/services/reservationSlice"
import { useSelector, useDispatch } from "react-redux";
// import { mdifMontantToChange } from "../../app/services/changeSlice";

const CalculatorVente = () => {
  const today = new Date();
  const month = today.getMonth()+1;
const year = today.getFullYear();
const date = today. getDate();
const currentDate = month + "/" + date + "/" + year;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    data: devises,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAllDevisesQuery();
  const members = devises?.map((devise) => (
    <MenuItem value={devise.name} key={devise.id}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Avatar alt="Remy Sharp" 
       // src={`https://exchange-maroc.com/laravel/storage/Devise/image/${devise.image}`} 
        src= {`${process.env.REACT_APP_BASE_FILE_URL}/Devise/image/${devise.image}`} 
        sx={{ width: 24, height: 24 }}/>
        {devise.name}
      </Box>
    </MenuItem>
  ));

  const [receiveSelectValue, setReceiveSelectValue] = useState("");
  const [giveSelectValue, setGiveSelectValue] = useState("");
  const [modified, setModified] = useState(false);
  const [prix, setPrix] = useState();

  useEffect(()=>{

  })

  useEffect(() => {
    if (modified === true) {
      setReceiveSelectValue("MAD Marocain");
    }
    setModified(false);
  }, [giveSelectValue]);

  useEffect(() => {
    if (modified === true) {
      setGiveSelectValue("MAD Marocain");
    }
    setModified(false);
  }, [receiveSelectValue]);

  const handleChange = (typeChange) => (event) => {
    const ddd = devises.find((d) => d.name === event.target.value);
    setModified(true);
    dispatch(deviseToChange(ddd));

    if (typeChange === "give") {
      dispatch(convert("achat"));
      setGiveSelectValue(event.target.value);
    }
    if (typeChange === "receive") {
      dispatch(convert("vente"));
      setReceiveSelectValue(event.target.value);
    }
  };

  const state = useSelector((state) => state.change.value);

  const handleChange1 = (event) => {
    if (state.typeOperation === "achat") {
      setPrix(state.deviseToExchange.prixAchat);
      dispatch(
        mdifMontantToChange({
          montant1: event.target.value,
          montant2: (event.target.value * (state.deviseToExchange.prixAchat*1000))/1000,
        })
      );
    } else if (state.typeOperation === "vente") {
      setPrix(state.deviseToExchange.prixVente);
      dispatch(
        mdifMontantToChange({
          montant1: event.target.value,
          montant2: (event.target.value / (state.deviseToExchange.prixVente*1000)*1000),
        })
      );
    }
  };
  //
  const handleChange2 = (event) => {
    const m1=(event.target.value / (prix*1000))*1000;
    if (state.typeOperation === "achat") {
      setPrix(state.deviseToExchange.prixAchat);
      
      
      

      dispatch(
        mdifMontantToChange({
          montant1: (event.target.value / (state.deviseToExchange.prixAchat*1000))*1000,
          montant2:parseFloat(event.target.value) ,
        })
      );
    } else if (state.typeOperation === "vente") {
      setPrix(state.deviseToExchange.prixVente);
      dispatch(
        mdifMontantToChange({
          montant1: (state.deviseToExchange.prixVente * 1000 * event.target.value)/1000 ,
          montant2: event.target.value,
        })
      );
    }
  };

      if (isLoading) {
      return <Loader/>
   }

  //  if (isError) {
  //     console.log(error)
  // }
  // if (isSuccess) {
  //  console.log(devises[0].prixVente)
  // }

  return (
    <Box
      sx={{
        backgroundColor: "white",
        padding: 2,
        marginBottom: 4,
        // marginTop:4,
        // m:4,

        maxWidth: "480px",
        borderRadius: 2,
        // border:'solid 1px black'
      }}
    >
      <Box
        sx={{
          backgroundColor: "#131312",
          color: "white",
          borderRadius: 2,
          padding: 2,
        }}
      >
        <Typography variant="h6" align="center">
        Selectionner la devise que vous souhaitez vendre dans la liste et saisir le montant que vous désirez changer
        </Typography>
        <Stack
          direction={{xs:'column-reverse',sm:'row-reverse'}}
          spacing={2}
          sx={{
            borderColor: "goldenrod",
            backgroundColor: "goldenrod",
            borderRadius: 2,
            borderStyle: "solid",
            padding: 1,
            input: { color: "yellow", fontSize: "1.5em" },
          }}
        >
          <TextField
            className="zonetext"
            sx={{
              minWidth: "110px",
              padding: 0,
              borderStyle: "solid",
              input: { color: "yellow", fontSize: "1.2em" },
            }}
            id="t1"
            label="Je paie"
            type="number"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            // inputRef={inputRef}
            value={state.montantToExchane}
            onChange={handleChange1}
            onFocus={(e) => e.target.select()}
          />

          <Box sx={{ minWidth: "200px", color: "red" }}>
            <FormControl fullWidth>
              <InputLabel id="give-select-label">Devise</InputLabel>
              <Select
                labelId="give-label"
                id="give"
                value={giveSelectValue}
                label="Devise"
                size="small"
                defaultValue="EURO"
                onChange={handleChange("give")}
              >
                {members}
              </Select>
            </FormControl>
          </Box>
        </Stack>
      </Box>
      <Box
        sx={{
          // padding:2,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Typography variant="h4">
          <SyncIcon
            sx={
              {
                // fontSize:'32px'
              }
            }
          />
        </Typography>
      </Box>

      {/*  */}
      <Box
        sx={{
          backgroundColor: "#131312",
          color: "white",
          borderRadius: 2,
          padding: 2,
        }}
      >
        <Typography variant="h6" align="center">
          Montant à recevoir
        </Typography>
        <Stack
          direction={{xs:'column-reverse',sm:'row-reverse'}}
          spacing={2}
          sx={{
            borderColor: "black",
            backgroundColor: "goldenrod",
            borderRadius: 2,
            borderStyle: "solid",
            padding: 1,
            input: { color: "yellow", fontSize: "1.5em" },
          }}
        >
          <TextField
            sx={{
              backgroundColor: "goldenrod",
              borderColor: "goldenrod",
              minWidth: "200px",
              padding: 0,
              borderStyle: "solid",
              input: { color: "yellow", fontSize: "1.2em" },
            }}
            id="t2"
            label="je reçois"
            type="number"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            value={state.MontantToReceive}
            onChange={handleChange2}
            onFocus={(e) => e.target.select()}
          />
          {/*  */}
          <Box sx={{ minWidth: "200px", color: "red" }}>
            <FormControl fullWidth disabled>
              <InputLabel id="receive-select-label">Monnaie locale</InputLabel>
              <Select
                labelId="receive-label"
                id="receive"
                // value={"EURO"}
                label="Devise"
                size="small"
                defaultValue="MAD Marocain"
                //  value={receiveSelectValue}
                // onChange={handleChange("receive")}
              >
                <MenuItem value="MAD Marocain">
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Avatar alt="Remy Sharp" src="/images/devise/maroc.jpg"  sx={{ width: 24, height: 24 }}/>
        MAD Marocain
      </Box>
    </MenuItem>

              </Select>
            </FormControl>
          </Box>
          {/* <BasicSelect typeChange={'receive'}/> */}
        </Stack>
      </Box>




    {state.montantToExchane !=0 && <>  
      <Box
        sx={{
          // padding:2,
          display: "flex",
          justifyContent: "center",
        }}
      ></Box>
      {/*  */}

      <Stack
        direction="row"
        spacing={2}
        sx={{
          padding: 2,
          justifyContent: "space-between",
          color: "black",
        }}
      >
        <Box>
          <Typography
            paragraph="true"
            sx={{
              marginBottom: 0,
            }}
          >
            <Typography>
              Vous {state.typeOperation === "achat" ? "vendez " : "acheter "} un
              montant de{" "}
              <strong>
             {state.typeOperation === "achat"? state.montantToExchane + " " + state.deviseToExchange.name:state.MontantToReceive+ " " + state.deviseToExchange.name}

              </strong>
            </Typography>
            <Typography>
              contre un montant de {" "}
              <strong>
              {state.typeOperation === "achat"? state.MontantToReceive + " MAD" :state.montantToExchane + " MAD"}
              </strong>
            </Typography>
          </Typography>
          <Typography variant="caption">
            avec le taux: {state.deviseToExchange.name && 1} {state.deviseToExchange.name}{" "} ={" "}
            {state.typeOperation === "achat"
              ? state.deviseToExchange.prixAchat
              : state.deviseToExchange.prixVente}{" MAD"}
            
          </Typography>
        </Box>
        <Box>
          <Typography paragraph="true">Le {currentDate}</Typography>
          <Typography paragraph="true">à 12H30</Typography>
        </Box>
      </Stack>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          paddingBottom: 2,
        }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: "black",
            color: "goldenrod",
          }}
          onClick={() => {
            dispatch(addBureau({
              bureauRetrait:"",
              dateRetrait:"",
              heureRetrait:"",
              steep:1}))
            navigate("/reservation/retraitinfo#bureau")}}
        >
          Reserver Votre argent
        </Button>
      </Box>
      </>}
    </Box>
  );
};

export default CalculatorVente;
