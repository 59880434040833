import { createSlice } from "@reduxjs/toolkit";
export const reservationSlice=createSlice({
    name:"reservation",
    initialState:{
        value:{
            // reservation:{},
            steep:0,
            bureauRetrait:'',
            dateRetrait:'',
            heureRetrait:'',
            indicatif:'+212(MA)',
            devise:"",
            taux:"",
            typeOperation:""
        }
    },
    reducers:{
        addBureau:(state,action)=>{
            state.value={...state.value,
                bureauRetrait:action.payload.bureauRetrait,
                dateRetrait:action.payload.dateRetrait,
                heureRetrait:action.payload.heureRetrait,
                steep:action.payload.steep,
            }
        },  
        setSteep: (state, action) => {
            state.value.steep = action.payload;
        } 
    }
});
 export const {convert,addBureau,setSteep}=reservationSlice.actions;
 export default reservationSlice.reducer;