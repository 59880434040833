import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const contactApi = createApi({
  reducerPath: 'contactApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    credentials: 'include',
  }),
  tagTypes: ["Contacts"], // Tags pour la gestion du cache
  keepUnusedDataFor: 60, // Temps de rétention des données non utilisées en cache
  endpoints: (builder) => ({
    getAllContacts: builder.query({
      query: () => ({
        url: 'messages/',
      }),
      providesTags: ['Contacts'],
    }),
    getContact: builder.query({
      query: (id) => ({
        url: `messages/${id}`,
      }),
      providesTags: ['Contacts'],
    }),
    addContact: builder.mutation({
      query: (body) => ({
        url: 'messages/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Contacts'],
    }),
    updateContact: builder.mutation({
      query: ({ id, body }) => ({
        url: `messages/${id}`,
        method: 'PUT', // Méthode HTTP pour la mise à jour
        body,
      }),
      invalidatesTags: ['Contacts'],
    }),
    deleteContact: builder.mutation({
      query: (id) => ({
        url: `messages/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Contacts'],
    }),
  }),
});

export const {
  useGetAllContactsQuery,
  useGetContactQuery,
  useAddContactMutation,
  useUpdateContactMutation,
  useDeleteContactMutation,
} = contactApi;
