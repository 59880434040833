import React from 'react';
import { Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

function PolitiqueCookies() {
  return (
    <Container
      maxWidth="md"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
        pt: { xs: 4, sm: 8 },
        pb: { xs: 4, sm: 8 },
        background: 'white',
        marginBottom: { xs: 4, sm: 8 },
      }}
    >
      <Typography
        variant="h3"
        align="center"
        sx={{
          fontSize: { xs: 24, sm: 56 },
          color: 'black',
          pb: { xs: 2, sm: 4 },
        }}
      >
        Politique sur les Cookies
      </Typography>
      
      <Typography variant="body1" paragraph>
        Chez EXCHANGE.COM, nous utilisons des cookies pour améliorer votre expérience utilisateur sur notre site web. Cette politique explique ce que sont les cookies, comment nous les utilisons, et vos droits concernant leur utilisation.
      </Typography>
      
        <Typography variant="h4" component="span">
          Qu'est-ce qu'un cookie ?
        </Typography>{' '}
        <Typography variant="body1" paragraph>
        <Typography variant="body1" paragraph></Typography>
        Les cookies sont de petits fichiers texte placés sur votre appareil pour collecter des informations standard de journal Internet et des informations comportementales. Lorsque vous visitez notre site, nous pouvons collecter des informations vous concernant automatiquement grâce à ces cookies.
      </Typography>
      
        <Typography variant="h4" component="span">
          Comment utilisons-nous les cookies ?
        </Typography>{' '}
        <Typography variant="body1" paragraph>
        Nous utilisons les cookies de différentes manières pour améliorer votre expérience sur notre site, y compris :
        <ul>
          <li>Maintenir votre session connectée lorsque vous naviguez sur notre site.</li>
          <li>Mémoriser vos préférences d'affichage et de navigation.</li>
          {/* <li>Analyser comment vous utilisez notre site pour améliorer son fonctionnement.</li> */}
        </ul>
      </Typography>
      
        <Typography variant="h4" component="span">
          Gestion des cookies :
        </Typography>{' '}
        <Typography variant="body1" paragraph>
        Vous pouvez gérer vos préférences de cookies en modifiant les paramètres de votre navigateur pour refuser certains types de cookies. Veuillez noter que le blocage des cookies peut affecter votre expérience sur notre site et limiter les fonctionnalités que nous pouvons offrir.
      </Typography>
      <Typography variant="body1" paragraph>
        En utilisant notre site web, vous consentez à notre utilisation des cookies conformément à cette politique. Si vous avez des questions ou des préoccupations concernant notre utilisation des cookies, veuillez nous contacter à {' '}
        <Typography component="span" color="primary" underline="hover">
        <Link href="mailto:contact@exchange-maroc.com">contact@exchange-maroc.com</Link>
        </Typography>
      </Typography>
    </Container>
  );
}

export default PolitiqueCookies;
