import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box, Button, Container, Stack, TextField } from "@mui/material";
import { useAddDeviseMutation } from "../../app/services/deviseApi";
import { useNavigate } from "react-router-dom";

const VisuallyHiddenInput = styled("input")({
  // backgroundColor:'black',
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function DeviseAdd() {
  const [addDevise,{data:devise1,isLoading,isError,error}]=useAddDeviseMutation();
  const navigate=useNavigate();
  const [chemainImage,setChemainImage]=useState("")
  const [devise,setDevise]=useState({
    name:"",
    pays:"",
    image:"",
    prixAchat:0,
    prixVente:0,
  })
  
  const handleForm = (e) => {
    const formData= new FormData();
    formData.append('name',devise.name);
    formData.append('pays',devise.pays);
    formData.append('prixAchat',devise.prixAchat);
    formData.append('prixVente',devise.prixVente);
    formData.append('image',devise.image);
    e.preventDefault();
    addDevise(
      formData
    ).then((data)=>{
        console.log(devise,'mes données',data);
    });
    navigate("/admin/deviselistedit");
  };


  const handleChange = () => {};
  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          backgroundColor: "goldenrod",
          padding: { xs: 0, sm: 2, md: 8 },
          borderRadius: 4,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",

          alignItems: "center",
          height: { xs: "50vh", sm: "50vh", md: "70vh" },
        }}
      >
        <form onSubmit={handleForm}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" spacing={2}>
            <TextField
              helperText="Entrer la devise"
              id="devise"
              label="Devise"
              // color="primary"
              onChange={(event) =>
                setDevise((state) => ({
                  ...state,
                  name: event.target.value,
                }))
              }
            />

            <TextField
              helperText="Entrer le pays"
              id="pays"
              label="pays"
              // color="primary"
              onChange={(event) =>
                setDevise((state) => ({
                  ...state,
                  pays: event.target.value,
                }))
              }
            />
            <TextField
              helperText="Entrer le prix d'achat"
              id="prixAchat"
              label="prix d'achat"
              type="text"
              // color="primary"
              onChange={(event) =>
                setDevise((state) => ({
                  ...state,
                  prixAchat: event.target.value,
                }))
              }
            />

            <TextField
              helperText="Entrer le prix de vente"
              id="prixVente"
              label="prix de vente"
              type="text"
              // color="primary"
              onChange={(event) =>
                setDevise((state) => ({
                  ...state,
                  prixVente: event.target.value,
                }))
              }
            />

          </Stack>
          <Stack direction={"column"} spacing={2}>
          <img src='images/maroc.jpg'alt='' width='240px'/>
          
          <Button
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
              onChange={(event) =>{
                setChemainImage(event.target.files[0].name)
                setDevise((state) => ({
                  ...state,
                  image: event.target.files[0],
                }))
                }
              }
            >
              Charger image
              <VisuallyHiddenInput type="file" />
            </Button>
            {/* <img src={chemainImage} width="240px" alt="devise"/> */}
            <h1>{chemainImage}</h1>
          </Stack>
          </Stack>
          <Button variant="contained" type="submit">Enregistrer</Button>
          
        </form>
      </Box>
    </Container>
  );
}

export default DeviseAdd;
 