import { Box, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import Bureau from './Bureau';

function RecapRetraitInfo() {
  const state = useSelector((state) => state.reservation.value);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <Box sx={{
      padding: 2,
      backgroundColor: "black",
      borderRadius: 2,
      color: "yellow"
    }}>
      <Typography variant='h6' sx={{backgroundColor:'yellow',color:'black'}}>Coordonnées de retrait</Typography>
      <Box>
        <Typography
          paragraph="true"
          sx={{
            marginBottom: 0,
          }}
        >
          <Typography>Votre date de retrait :</Typography>
          <Typography>
            <strong>
              {formatDate(state.dateRetrait)}
            </strong>
          </Typography>
          <Typography>Votre heure de retrait :</Typography>
          <Typography>
            <strong>
              {state.heureRetrait}
            </strong>
          </Typography>
          <Typography>Votre Bureau de retrait :</Typography>
        </Typography>
      </Box>
      <Box>
        <Bureau id={state.bureauRetrait} />
      </Box>
    </Box>
  );
}

export default RecapRetraitInfo;
