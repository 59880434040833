import {createApi,fetchBaseQuery} from '@reduxjs/toolkit/query/react'

export const bureauApi=createApi({
    reducerPath:'bureauApi',
    baseQuery:fetchBaseQuery({
        // baseUrl:'http://localhost:8000/api/bureaus'
        baseUrl: process.env.REACT_APP_BASE_URL
    }),
    keepUnusedDataFor:60,
    endpoints:(builder)=>({

        getAllBureaus:builder.query({
            query:()=>({url:'bureaus/',
            })
        }),
        getBureau:builder.query({
            query:(id)=>({url:`bureaus/${id}`,
            })
        }),
        addBureau:builder.mutation({
            query:(body)=>({
                url:'bureaus/',
                method:'POST',
                body
            })
        })        
    })
})
export const {useGetAllBureausQuery,useGetBureauQuery,useAddBureauMutation}=bureauApi;
