import React,{useEffect} from "react";
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import SwipeableTextMobileStepper from "../news/SwipeableTextMobileStepper";
import Logo from "../simulator/Logo";
import NewsList from "../news/NewsList";
import FaqsList from "../faqs/FaqsList";
// import ConstructionMessage from "./ConstructionMessage";
import Steeps from "../steeps/Steeps";
import { Helmet } from "react-helmet-async";

const Actualites = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    // Remettre le défilement en haut de la page à chaque chargement
    window.scrollTo(0, 0);
}, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100vw",
        pt: { xs: 4, sm: 8 },
        pb: { xs: 2, sm: 4 },
        pl: { xs: 4, sm: 8 },
        pr: { xs: 4, sm: 8 },
        mb: 4,
        background: "#ffdb02",
      }}
    >
      <Helmet>
        <title>
          EXCHANGE MAROC - Change de devises à Marrakech et au Maroc
        </title>
        <meta
          name="description"
          content="EXCHANGE.COM propose des services fiables de change de devises à Marrakech et au Maroc. Échangez facilement des dirhams marocains (MAD) avec nous. Visitez notre site pour découvrir nos taux compétitifs et effectuer des transactions en toute simplicité."
        />
      </Helmet>
      <Typography
      variant="h3"
      align="center"
      sx={{
        fontSize: { xs: 24, sm: 56 },
        color: 'black',
        pb: { xs: 4, sm: 8 },
        animation: 'slideDown 0.8s ease-out',
        '@keyframes slideDown': {
          '0%': {
            opacity: 0,
            transform: 'translateY(-100px)',
          },
          '100%': {
            opacity: 1,
            transform: 'translateY(0)',
          },
        },
      }}
    >
            Restez informé avec nos dernières actualités
          </Typography>
<Stack direction={{ xs: 'column', sm: 'row' }}>
<Box>
  <Typography variant="h4">
            Nos actualités
  </Typography>
          <Typography
            paragraph
            component="h4"
            //variant={isSmallScreen ? "subtitle1" : "h5"} // Utilisation de subtitle1 pour les petits écrans
            sx={{
              color: "#212529",
              mb: 2, // Ajout de margin bottom
            }}
          >
            Suivez toutes les mises à jour et informations importantes sur notre
            site. Nous vous proposons des articles, des nouvelles, et des
            conseils pour vous tenir au courant des événements récents.
            Consultez régulièrement cette section pour ne rien manquer sur les
            tendances, les nouveautés et les actualités marquantes.
          </Typography>
          </Box>
          <SwipeableTextMobileStepper />
          </Stack>
        {/* </Stack>
      </Box> */}
    </Box>
  );
};

export default Actualites;
