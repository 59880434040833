import React,{useEffect} from 'react'

import Simulator from '../simulator/Simulator'
import { Box, Container, Stack, Typography } from '@mui/material'
import Conditions from '../reservation/Conditions'
import DeviseList from '../devise/DeviseList'
import ServiceCard from './ServiceCard';
import { Helmet } from 'react-helmet-async';

function Services() {
useEffect(() => {
    // Remettre le défilement en haut de la page à chaque chargement
    window.scrollTo(0, 0);
}, []);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width:'100vw',
        pt: { xs: 4, sm: 8 },
        pb: { xs: 2, sm: 4 },
        pl: { xs: 4, sm: 8 },
        pr: { xs: 4, sm: 8 },
        mb:4,
        background: "#ffdb02",
      }}
    >
            <Helmet>
        <title>Nos services - EXCHANGE.COM</title>
        <meta
          name="description"
          content="EXCHANGE.COM propose des services fiables de change de devises à Marrakech et au Maroc. Échangez facilement des dirhams marocains (MAD) avec nous. Visitez notre site pour découvrir nos taux compétitifs et effectuer des transactions en toute simplicité."
        />
      </Helmet>
      <Typography
      variant="h3"
      align="center"
      sx={{
        fontSize: { xs: 24, sm: 56 },
        color: 'black',
        pb: { xs: 4, sm: 8 },
        animation: 'slideDown 0.8s ease-out',
        '@keyframes slideDown': {
          '0%': {
            opacity: 0,
            transform: 'translateY(-100px)',
          },
          '100%': {
            opacity: 1,
            transform: 'translateY(0)',
          },
        },
      }}
    >
        Profitez de nos services et promotions
      </Typography>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 1, sm: 2,fontSize: { xs: 32, sm: 56 }, md: 4 }} useFlexGap flexWrap="wrap">
        <ServiceCard/>
        {/* <ServiceCard/>
        <ServiceCard/>
        <ServiceCard/>
        <ServiceCard/> */}
      </Stack>     
    </Box>
  )
}

export default Services
