import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const API_BASE_URL = process.env.REACT_APP_BASE_URL;

export const notificationApi = createApi({
    reducerPath: 'notificationApi',
    baseQuery: fetchBaseQuery({ 
      baseUrl: API_BASE_URL,
      credentials: 'include',
    }),
    tagTypes:["Notifications"],
    keepUnusedDataFor:60,
    endpoints: (builder) => ({
      getUserNotifications: builder.query({
        query: (userId) => `/notifications/${userId}`,
        providesTags:['Notifications']
      }),
      
      markNotificationAsRead: builder.mutation({
        query: (notificationIds) => ({
          url: `/notifications/mark-as-read`,
          method: 'POST',
          body: { notification_ids: notificationIds },
        }),
        invalidatesTags: ['Notifications'],
      }),
    }),
  });
  
  export const { useGetUserNotificationsQuery, useMarkNotificationAsReadMutation } = notificationApi;
  