import React, { useState,useEffect } from "react";
import { useParams } from 'react-router-dom';
import dayjs from "dayjs";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputLabel,
  TextField,
  Stack,
  Button,
  Box,
  Select,
  MenuItem,
} from "@mui/material";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { useGetAllReservationsQuery } from "../../../app/services/reservationApi";
import { useNavigate } from "react-router-dom";
import Loader from "../../loader/Loader";

function GestionReservation() {

  const navigate = useNavigate();
  const {etat}=useParams();

  const [code, setCode] = useState("");
  const [nomFamille, setNomFamille] = useState("");
  const [bureau, setBureau] = React.useState('');
  useEffect(() => {
    // Remettre le défilement en haut de la page à chaque chargement
    window.scrollTo(0, 0);
  }, [etat]);
  const handleChange = (event) => {
    setBureau(event.target.value);
  };
  const {
    data: reservations,
    isLoading,
    isError,
  } = useGetAllReservationsQuery(etat);

  if (isLoading) {
    return <Loader />;
  }

  const filteredReservations =
  reservations &&
  reservations.filter((reservation) => {
    const bureauMatch =
      bureau === 0 || reservation.bureauRetrait.toString().includes(bureau);
    return (
      reservation.nomFamille.toLowerCase().includes(nomFamille.toLowerCase()) &&
      reservation.id.toString().includes(code) &&
      bureauMatch
    );
  });


  return (
    <Container
      maxWidth="xl"
      sx={{
        backgroundColor: "#ffff8d",
        pt:0,
        pb:2,
        marginTop:0,
       // padding: 4,
      }}
    >
      <Typography variant="h5">Réservations {etat==='encours'&&'en cours'}{etat==='validee'&&'validées'} {etat==='terminee'&&'términées'} </Typography>
      <Typography paragraph>
        Dans cette section, vous pouvez gérer les réservations effectuées par les clients.
      </Typography>

      <Stack direction={"row"} align="left" spacing={2} sx={{pt:1,pb:1,pl:4}}>
          <Box sx={{ width: 64, backgroundColor: '#ff9800' }}></Box>
            <Typography variant="body2">Couleur des réservations qui dépassent 100 000,00 MAD</Typography>
          </Stack>
          <Stack direction={"row"} align="left" spacing={2} sx={{pt:1,pb:1,pl:4}}>
            <Box sx={{width:64, backgroundColor:'#ffeb3b'}}></Box>
            <Typography variant="body2">Couleur des réservations qui dépassent 50 000,00 MAD</Typography>
          </Stack>

   

      <Stack
        direction={"row"}
        spacing={4}
        align="left"
        sx={{
          backgroundColor: "white",
          border:'solid',
          marginBottom: 2,
          borderRadius: 2,
          padding: 2,
          display: "flex",
        }}
      >

<Stack direction={"column"} align="left">
          <Typography variant="h6">Filtre</Typography>
          <Typography variant="body2">
            Filtre des es réservations selon le code, le nom du client ou le bureau de retrait
          </Typography>
        </Stack>
        <Stack direction={"column"} spacing={2} align="left">
          <InputLabel id="code">Code réservation</InputLabel>
          <TextField
            id="code"
            name="code"
            size="small"
            value={code}
            onChange={(event) => setCode(event.target.value)}
          />
        </Stack>
        <Stack direction={"column"} spacing={2} align="left">
          <InputLabel id="nomFamille">Nom de famille</InputLabel>
          <TextField
            id="nomFamille"
            name="nomFamille"
            size="small"
            value={nomFamille}
            onChange={(event) => setNomFamille(event.target.value)}
          />
        </Stack>
        <Stack direction={"column"} spacing={2} align="left">
        <InputLabel id="demo-simple-select-label">Bureau de retrait</InputLabel>
        <Select
          sx={{width:240}}
          size="small"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={bureau}
          label="Bureau"
          onChange={handleChange}
        >
          <MenuItem value={0}>Tout</MenuItem>
          <MenuItem value={1}>Exchange.com</MenuItem>
          <MenuItem value={2}>Régionale de change</MenuItem>
          <MenuItem value={3}>Sud Exchange</MenuItem>
          <MenuItem value={4}>Travel Exchange</MenuItem>
          <MenuItem value={6}>Mad Exchange</MenuItem>
          <MenuItem value={7}>Finaction</MenuItem>
        </Select>
        </Stack>

      </Stack>

      <TableContainer component={Paper} sx={{ minHeight:400 }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow sx={{backgroundColor:'black'}}>
              <TableCell align="left" sx={{ fontWeight: 'bold',color:'yellow'}}>Code</TableCell>
              <TableCell align="left" sx={{ fontWeight: 'bold',color:'yellow'}}>Nom</TableCell>
              {/* <TableCell align="left" sx={{ fontWeight: 'bold',color:'yellow'}}>Prénom</TableCell> */}
              <TableCell align="left" sx={{ fontWeight: 'bold',color:'yellow'}}>Transaction</TableCell>
              <TableCell align="left" sx={{ fontWeight: 'bold',color:'yellow'}}>Montant</TableCell>
              <TableCell align="left" sx={{ fontWeight: 'bold',color:'yellow'}}>Devise</TableCell>
              <TableCell align="left" sx={{ fontWeight: 'bold',color:'yellow'}}>MAD</TableCell>  
              <TableCell align="left" sx={{ fontWeight: 'bold',color:'yellow'}}>Date reservation</TableCell> 
              <TableCell align="left" sx={{ fontWeight: 'bold',color:'yellow'}}>Date returnetrait</TableCell>  
                         
              <TableCell align="left" sx={{ fontWeight: 'bold',color:'yellow'}}>Détail</TableCell>
              
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredReservations &&
              filteredReservations.map((reservation) => (
                <TableRow
                  key={reservation.id}
                //  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}

                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  marginLeft:4,
                  marginRight:4,
                  backgroundColor: 
                    reservation.typeOperation === "achat"
                      ? reservation.MontantToReceive > 100000
                        ? "#ff9800"
                        : reservation.MontantToReceive > 50000
                        ? "#ffeb3b"
                        : "white"
                      : reservation.montantToExchange > 100000
                      ? "#ff9800"
                      : reservation.montantToExchange > 50000
                      ? "#ffeb3b"
                      : "white",
                }}
                




                >
                  <TableCell align="left">{reservation.id}</TableCell>
                  <TableCell align="left">{reservation.nomFamille} {reservation.prenom}</TableCell>
                  {/* <TableCell align="left">{reservation.nomFamille2}</TableCell> */}
                  {/* <TableCell align="left">{reservation.prenom}</TableCell> */}



                  <TableCell align="left">
                            {reservation.typeOperation}
                          </TableCell>
                          <TableCell align="left">
                            {reservation.typeOperation === "achat"
                              ? reservation.montantToExchange
                              : reservation.MontantToReceive} 
                          </TableCell>
                          <TableCell align="left">{reservation.devise}</TableCell>
                          <TableCell align="left">
                            {reservation.typeOperation === "achat"
                              ? reservation.MontantToReceive
                              : reservation.montantToExchange}
                          </TableCell>
                          <TableCell align="left">{dayjs(reservation.created_at).format('DD/MM/YYYY')}</TableCell>
                          <TableCell align="left">{dayjs(reservation.dateRetrait).format('DD/MM/YYYY')}</TableCell>
                          
                  <TableCell align="left">
                    <Button sx={{ fontWeight: 'bold',color:'#212121'}}
                      size="small"
                      onClick={() => {
                        navigate(`/admin/detailsreservation/${reservation.id}`);
                      }}
                    >
                      <ManageAccountsIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default GestionReservation;
