import { Box, Link, List, Typography } from "@mui/material";
import React from "react";

function Conditions() {
  return (
    <Box>
      <List
        sx={{
          width: "100%",
          maxWidth:{xs:380,sm:640},
          backgroundColor: '#EFE9B7',
          color:'black',
          padding:2,
          position: "relative",
          overflow: "auto",
          maxHeight: 200,
          "& ul": { padding: 0 },
        }}
        subheader={<li />}
      >
        <Typography variant="h6">
          Dispositions réglementaires relatives aux opérations de change au
          Maroc
        </Typography>
        <ul>
          <li>
            - Les opérations de change doivent porter sur les devises cotées par
            Bank AL Maghrib,
          </li>
          <li>
            - La société de change Prendra les mesures nécessaires en vue
            d’informer Bank Al-Maghrib et les autorités compétentes de toute
            fausse monnaie présentée à leurs guichets par le client qui doit
            assumer les conséquences juridiques et judicaires engendrées ;
          </li>
          <li>
            - Opérations d’achat autorisées :la société est autorisée à acheter
            contre des dirhams, des billets de banque étrangers aux étrangers
            résidents ou non-résidents, aux marocains résidant à l’étranger
            ainsi qu’aux marocains résidents ;{" "}
          </li>
          <li>
            - pour les opérations d’achat de devises dont le montant est égal ou
            supérieur à la contrevaleur de 100.000 dirhams, le client doit
            présenter : la Carte Nationale d’Identité ou le passeport pour les
            marocains résidents et les marocains résidant à l’étranger ; - le
            passeport pour les étrangers non- résidents ; - la Carte
            d’Immatriculation ou le passeport pour les étrangers résidents ; -
            l’original de la déclaration d’importation de devises billets de
            banque souscrite auprès des services douaniers des frontières ;{" "}
          </li>
          <li>
            - Opérations de vente autorisées : Vendre des devises en billets de
            banque, au titre de la dotation pour voyages personnels à des
            marocains résidents et à des marocains résidant à l’étranger ; 
            Vendre des devises en billets de banque, au titre de dotations pour
            missions et stages à l’étranger du personnel du secteur public ; 
            Vendre des devises en billets de banque, au titre des opérations de
            rachat du reliquat des dirhams initialement convertis ;
          </li>
          <li>
            - Les dotations pour voyage personnel sont effectuées sur la base :
            - de la Carte Nationale d’Identité pour les marocains résidents et
            les marocains résidents à l’étranger ; - copie du passeport pour les
            étrangers non-résidents de passage au Maroc ; - pour le supplément
            de la dotation pour voyages personnels, il est servi sur la base de
            tout document, justifiant le paiement au Maroc de l’Impôt sur le
            Revenu au cours de l’année précédente, délivré par l’Administration
            marocaine ;{" "}
          </li>
          <li>
            - Les personnes physiques non-résidentes peuvent échanger le
            reliquat des dirhams préalablement achetés, sur présentation du
            bordereau de change ou tout autre document en tenant lieu (reçu de
            retrait de dirhams des guichets automatiques bancaires, ticket de
            change délivré par les automates de change) justifiant l’origine des
            dirhams.
          </li>
          <li>
            - Tout montant servi et non utilisé au cours d’un voyage à
            l’étranger ou suite à l’annulation du voyage doit être cédé sur le
            marché des changes dans un délai de 30 jours à compter de la date
            d’octroi des dotations pour voyages ou à compter de la date du
            retour au Maroc de la personne à laquelle la dotation a été servie
            pour le cas de voyage réalisé ;
          </li>
        </ul>
        <Typography variant="h6">
          Pour plus de détails sur les opérations de change manuel veuillez
          consulter le cadre réglementaire de l’Office des changes :
          <Link href="https://www.oc.gov.ma/fr/reglementations?field_categorie_reglementation_target_id=40&field_thematique_value=All#wow-book/" color="inherit">
            {'color="inherit"'}
            cliquer ici
          </Link>
        </Typography>
        <ul>
          <li>
            - La réservation est « intuitu personae » : la personne qui a
            réservé les devises via le site doit se présenter lui-même pour le
            dénouement de l’opération auprès du bureau de change ;
          </li>
          <li>
            - Un délai maximum de trois (3) jours est accordé pour la conclusion
            de l’opération de change initiée sur ce site, au-delà de ce délai,
            l’opération est automatiquement annulée
          </li>
          <li>
            - Confidentialité : les informations personnelles seront traitées
            conformément à la politique de confidentialité en vigueur et
            qu’elles ne seront utilisées que dans le cadre de l’opération de
            change{" "}
          </li>
          <li>
            - La réalisation des opérations de change est irréversible après
            inscription sur le système de la société, la responsabilité du
            client est engagée, il invité à vérifier les informations de
            l’opération avant la validation ;
          </li>
          <li>
            - Le client est responsable de fournir les informations correctes et
            la société n’est pas responsable des erreurs ou des retards causés
            par des informations incorrectes ;
          </li>
          <li>
            - Taux de change peut varier en fonction des fluctuations du marché
            et que le taux final sera confirmé au moment de la validation de la
            transaction.
          </li>
        </ul>
      </List>
    </Box>
  );
}
export default Conditions;
