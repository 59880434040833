import {
  Box,
  Grid,
  TextField,
  Typography,
  InputLabel,
  Stack,
  Checkbox,
  Button,
  Link,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { useAddReservationMutation } from "../../app/services/reservationApi";
import { useSelector } from "react-redux";
import Login from "../admin/authentification/Login";
import Conditions from "./Conditions";
import Loader from "../loader/Loader";

function ClientAuth() {
  const navigate = useNavigate();
  const state = useSelector((state) => state.change.value);
  const stateRetrait = useSelector((state) => state.reservation.value);
  const [addReservation, { data: reservation1, isLoading, isError, error }] =
    useAddReservationMutation();
  const { user } = useAuth();

  const [isChecked, setIsChecked] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setIsButtonEnabled(isChecked);
  }, [isChecked]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleClick = () => {
    if (!user.user) {
      console.error("User is not defined");
      return;
    }

    const reservation = {
      user_id: user.user.id,
      nomFamille: user.user.name,
      email: user.user.email,
      montantToExchange: state.montantToExchane,
      MontantToReceive: state.MontantToReceive,
      typeOperation: state.typeOperation,
      devise: state.deviseToExchange.name,
      taux:
        state.typeOperation === "achat"
          ? state.deviseToExchange.prixAchat
          : state.deviseToExchange.prixVente,
      bureauRetrait: stateRetrait.bureauRetrait,
      dateRetrait: stateRetrait.dateRetrait,
      heureRetrait: stateRetrait.heureRetrait,
      devise_id: state.deviseToExchange.id,
    };


    setLoading(true);
    addReservation(reservation)
      .then((data) => {
        if (data && data.data && data.data.id) {
          navigate(`/reservation/confirmation/${data.data.id}`);
        } else {
          console.error("Erreur : les données de réservation sont manquantes");
          alert(
            "Erreur : les données de réservation sont manquantes",
            data.data.id
          );
        }
      })
      .catch((error) => {
        console.error("Erreur lors de l'ajout de la réservation :", error);
        alert("Une erreur inattendue s'est produite. Veuillez réessayer.");
      }).finally(() => {
        setLoading(false);  // Set loading to false after API call
      });
  };

  return (
    <Stack
      direction="column"
      spacing={2}
    >
      {loading?(<Loader />):(
      <Box
      sx={{
        backgroundColor: "white",
        borderRadius: 2,
        border: "solid 1px goldenrod",
      }}
    >
    <Box
      sx={{
        backgroundColor: "black",
        borderRadius: "8px 8px 0 0",
        // padding: 2,
        // textAlign: "center",
      }}
    >
      <Typography variant="h5" color="goldenrod">
        {user ? "Bienvenue" : "Se connecter"}
      </Typography>
    </Box>
    <Box sx={{ padding: 2 }}>
      {user ? (
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel id="code-client-label">Code client</InputLabel>
              <TextField
                fullWidth
                disabled
                label="Code client"
                id="codeclient"
                name="codeclient"
                size="small"
                value={user.user.id}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel id="nom-client-label">Nom du client</InputLabel>
              <TextField
                fullWidth
                disabled
                label="Nom du client"
                id="nomClient"
                name="nomClient"
                size="small"
                value={user.user.name}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel id="email-label">Email</InputLabel>
              <TextField
                fullWidth
                disabled
                label="Email"
                id="email"
                name="email"
                size="small"
                value={user.user.email}
              />
            </Grid>
          </Grid>
          {state.typeOperation !== "achat" && (
            <Typography variant="h5">
              Merci de présenter vos documents d'identité au moment du retrait
            </Typography>
          )}
          <Conditions />
          <Stack direction={"row"} spacing={2} justifyContent={"left"}>
            <Checkbox
              label=""
              color="primary"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
              <Typography
                paragraph="true"
                sx={{ padding: "16px 0", m: 0 }}
                color="secondary"
              >
                J'ai lu et j'accepte
                <Link color="inherit" href="/conditions" target="_blank" rel="noopener noreferrer">
                  La politique de confidentialité et les conditions générales d'utilisation du
                  site
                </Link>
              </Typography>
          </Stack>
          <Button
            variant="contained"
            type="submit"
            disabled={!isButtonEnabled}
            onClick={handleClick}
          >
            Passer ma reservation
          </Button>
        </Box>
      ) : (
        <Login connection="reservation" />
      )}
    </Box>
    </Box>
      )}

    </Stack>
  );
}

export default ClientAuth;
