import React from "react";
import {
  Container,
  List,
  ListItem,
  ListItemText,
  Typography,
  Link,
} from "@mui/material";

function ConditionsGenerales() {
  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        pt: { xs: 4, sm: 8 },
        pb: { xs: 4, sm: 8 },
        background: "white",
        marginBottom: { xs: 4, sm: 8 },
      }}
    >
      <Typography
        variant="h3"
        align="center"
        sx={{
          fontSize: { xs: 24, sm: 56 },
          color: "black",
          pb: { xs: 2, sm: 4 },
        }}
      >
        Conditions Générales d'utilisation du site
      </Typography>
      <Typography variant="body1" paragraph>Date de la dirnière  mise à jour de cette Politique de Confidentialité: 29/06/2024</Typography>
      <Typography variant="body1" paragraph>
        <Link href="/politiqueconfidentialite" underline="hover" color="blue">
          Cliquez ici pour consulter notre politique de confidentialité
        </Link>
      </Typography>
      <List
        sx={{
          width: "100%",
          backgroundColor: "yellow",
          color: "black",
          padding: 2,
          position: "relative",
          overflow: "auto",
          "& li": { marginBottom: 1 },
        }}
        subheader={<li />}
      >
        <Typography variant="h4" paragraph>
          <strong>Introduction :</strong>
        </Typography>
        <Typography variant="body1" paragraph>
          Les présentes Conditions Générales d'Utilisation (ci-après dénommées
          "CGU") régissent l'accès et l'utilisation du site web exchange.com
          ainsi que des services et contenus disponibles sur le Site.
        </Typography>
        <Typography variant="body1" paragraph>
          En accédant au Site, vous acceptez d'être lié(e) par ces CGU dans leur
          intégralité. Si vous n'acceptez pas ces CGU, veuillez ne pas utiliser
          le Site.
        </Typography>
        <Typography variant="h4" paragraph>
          <strong>Modifications des CGU :</strong>
        </Typography>
        <Typography variant="body1" paragraph>
          Nous nous réservons le droit de modifier ces CGU à tout moment. Les
          modifications entreront en vigueur dès leur publication sur le Site.
          Il est de votre responsabilité de consulter régulièrement les CGU pour
          prendre connaissance de toute modification. L'utilisation continue du
          Site après la publication des modifications constitue votre
          acceptation des CGU modifiées.
        </Typography>
        <Typography variant="h4" paragraph>
          <strong>
            <Typography variant="h4" paragraph>
              <strong>
                <Typography variant="h4" paragraph>
                  <strong>Accès au Site :</strong>
                </Typography>
                <Typography variant="body1" paragraph>
                  L'accès au Site est autorisé de manière temporaire et nous
                  nous réservons le droit de retirer ou modifier le service que
                  nous fournissons sur le Site sans préavis. Nous ne serons pas
                  tenus responsables si, pour une raison quelconque, le Site est
                  indisponible à tout moment ou pour toute période.
                </Typography>{" "}
              </strong>
            </Typography>
            <Typography variant="body1" paragraph>
              Nous nous réservons le droit de modifier ces CGU à tout moment.
              Les modifications entreront en vigueur dès leur publication sur le
              Site. Il est de votre responsabilité de consulter régulièrement
              les CGU pour prendre connaissance de toute modification.
              L'utilisation continue du Site après la publication des
              modifications constitue votre acceptation des CGU modifiées.
            </Typography>{" "}
          </strong>
        </Typography>
        <Typography variant="body1" paragraph>
          Nous nous réservons le droit de modifier ces CGU à tout moment. Les
          modifications entreront en vigueur dès leur publication sur le Site.
          Il est de votre responsabilité de consulter régulièrement les CGU pour
          prendre connaissance de toute modification. L'utilisation continue du
          Site après la publication des modifications constitue votre
          acceptation des CGU modifiées.
        </Typography>

        <Typography variant="h5" component="div">
          Dispositions réglementaires relatives aux opérations de change au
          Maroc
        </Typography>

        <ListItem>
          <ListItemText primary="- Les opérations de change doivent porter sur les devises cotées par Bank AL Maghrib," />
        </ListItem>
        <ListItem>
          <ListItemText primary="- La société de change Prendra les mesures nécessaires en vue d’informer Bank Al-Maghrib et les autorités compétentes de toute fausse monnaie présentée à leurs guichets par le client qui doit assumer les conséquences juridiques et judicaires engendrées ;" />
        </ListItem>
        <ListItem>
          <ListItemText primary="- Opérations d’achat autorisées :la société est autorisée à acheter contre des dirhams, des billets de banque étrangers aux étrangers résidents ou non-résidents, aux marocains résidant à l’étranger ainsi qu’aux marocains résidents ; " />
        </ListItem>
        <ListItem>
          <ListItemText primary="- pour les opérations d’achat de devises dont le montant est égal ou supérieur à la contrevaleur de 100.000 dirhams, le client doit présenter : la Carte Nationale d’Identité ou le passeport pour les marocains résidents et les marocains résidant à l’étranger ; - le passeport pour les étrangers non- résidents ; - la Carte d’Immatriculation ou le passeport pour les étrangers résidents ; - l’original de la déclaration d’importation de devises billets de banque souscrite auprès des services douaniers des frontières ;" />
        </ListItem>
        <ListItem>
          <ListItemText primary="- Opérations de vente autorisées : Vendre des devises en billets de banque, au titre de la dotation pour voyages personnels à des marocains résidents et à des marocains résidant à l’étranger ;  Vendre des devises en billets de banque, au titre de dotations pour missions et stages à l’étranger du personnel du secteur public ;  Vendre des devises en billets de banque, au titre des opérations de rachat du reliquat des dirhams initialement convertis ;" />
        </ListItem>
        <ListItem>
          <ListItemText primary="- Les dotations pour voyage personnel sont effectuées sur la base : - de la Carte Nationale d’Identité pour les marocains résidents et les marocains résidents à l’étranger ; - copie du passeport pour les étrangers non-résidents de passage au Maroc ; - pour le supplément de la dotation pour voyages personnels, il est servi sur la base de tout document, justifiant le paiement au Maroc de l’Impôt sur le Revenu au cours de l’année précédente, délivré par l’Administration marocaine ;" />
        </ListItem>
        <ListItem>
          <ListItemText primary="- Les personnes physiques non-résidentes peuvent échanger le reliquat des dirhams préalablement achetés, sur présentation du bordereau de change ou tout autre document en tenant lieu (reçu de retrait de dirhams des guichets automatiques bancaires, ticket de change délivré par les automates de change) justifiant l’origine des dirhams." />
        </ListItem>
        <ListItem>
          <ListItemText primary="- Tout montant servi et non utilisé au cours d’un voyage à l’étranger ou suite à l’annulation du voyage doit être cédé sur le marché des changes dans un délai de 30 jours à compter de la date d’octroi des dotations pour voyages ou à compter de la date du retour au Maroc de la personne à laquelle la dotation a été servie pour le cas de voyage réalisé ;" />
        </ListItem>
        <Typography variant="h6" component="div">
          Pour plus de détails sur les opérations de change manuel veuillez
          consulter le cadre réglementaire de l’Office des changes :
          <Link
            href="https://www.oc.gov.ma/fr/reglementations?field_categorie_reglementation_target_id=40&field_thematique_value=All#wow-book/"
            color="inherit"
          >
            cliquer ici
          </Link>
        </Typography>
        <ListItem>
          <ListItemText primary="- La réservation est « intuitu personae » : la personne qui a réservé les devises via le site doit se présenter lui-même pour le dénouement de l’opération auprès du bureau de change ;" />
        </ListItem>
        <ListItem>
          <ListItemText primary="- Un délai maximum de trois (3) jours est accordé pour la conclusion de l’opération de change initiée sur ce site, au-delà de ce délai, l’opération est automatiquement annulée" />
        </ListItem>
        <ListItem>
          <ListItemText primary="- Confidentialité : les informations personnelles seront traitées conformément à la politique de confidentialité en vigueur et qu’elles ne seront utilisées que dans le cadre de l’opération de change" />
        </ListItem>
        <ListItem>
          <ListItemText primary="- La réalisation des opérations de change est irréversible après inscription sur le système de la société, la responsabilité du client est engagée, il invité à vérifier les informations de l’opération avant la validation ;" />
        </ListItem>
        <ListItem>
          <ListItemText primary="- Le client est responsable de fournir les informations correctes et la société n’est pas responsable des erreurs ou des retards causés par des informations incorrectes ;" />
        </ListItem>
        <ListItem>
          <ListItemText primary="- Taux de change peut varier en fonction des fluctuations du marché et que le taux final sera confirmé au moment de la validation de la transaction." />
        </ListItem>
      </List>
    </Container>
  );
}

export default ConditionsGenerales;
