import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';


const API_BASE_URL = process.env.REACT_APP_BASE_URL; 


export const faqsApi = createApi({
  reducerPath: 'faqsApi',
  baseQuery: fetchBaseQuery({ 
    baseUrl: API_BASE_URL,
    credentials: 'include',
  }),
  endpoints: (builder) => ({
   
    getFaqs: builder.query({
      query: () => '/faqs', 
    }),
    createFaq: builder.mutation({
      query: (newFaq) => ({
        url: '/faqs',
        method: 'POST',
        body: newFaq,
      }),
    }),
    // Définissez un point de terminaison pour mettre à jour une FAQ existante
    updateFaq: builder.mutation({
      query: ({ id, updatedFaq }) => ({
        url: `/faqs/${id}`,
        method: 'PUT',
        body: updatedFaq,
      }),
    }),
    // Définissez un point de terminaison pour supprimer une FAQ
    deleteFaq: builder.mutation({
      query: (id) => ({
        url: `/faqs/${id}`,
        method: 'DELETE',
        body:id
      }),
    }),
  }),
});

// Exportez les actions générées par l'API
export const { useGetFaqsQuery, useCreateFaqMutation, useUpdateFaqMutation, useDeleteFaqMutation } = faqsApi;
