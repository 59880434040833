import { createSlice } from "@reduxjs/toolkit";
export const changeSlice=createSlice({
    name:"change",
    initialState:{
        value:{
            deviseToExchange:{
                id:'',
                name: '',
                pays: '',
                image: '',
                prixAchat: 0,
                prixVente: 0
            },
            typeOperation:'',
            montantToExchane:0,
            MontantToReceive:0,  
            deviseInit:'MAD Marocain'
        }
    },
    reducers:{
        convert:(state,action)=>{
            state.value={...state.value,typeOperation:action.payload}
        },
        mdifMontantToChange:(state,action)=>{
            // state.value={montantToExchane:actionn.payload.montant1,MontantToReceive:actionn.payload.montant2}
            state.value= {...state.value,montantToExchane:action.payload.montant1,MontantToReceive:action.payload.montant2}
        },        
        deviseToChange:(state,action)=>{
            state.value={...state.value,deviseToExchange:action.payload,montantToExchane:0,MontantToReceive:0}
        },      
    }
});
 export const {convert,mdifMontantToChange,deviseToChange}=changeSlice.actions;
 export default changeSlice.reducer;