import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export default function ServiceCard() {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        sx={{ height: 140 }}
        image="images/La regionale Exchange.jpeg"
        title="Exchange Fidilité"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Exchange Service
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Vous pouvez réserver vos devises de change selon votre opération
          d'achat ou de vente. La réservation n'est pas obligatoire; vous pouvez
          soit nous appeler afin de vérifier la disponibilité des devises en
          fonction de votre montant d'échange, soit nous joindre directement à
          l'agence.
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" >Plus de détails</Button>
      </CardActions>
    </Card>
  );
}
