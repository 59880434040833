import React from 'react';
import { Box, Card, CardContent, CardMedia, Container, Stack, Typography } from '@mui/material';
import ForwardIcon from '@mui/icons-material/Forward';

const Steep = ({ image, title, description }) => {
  return (
    <Card 
      sx={{ 
        display: 'flex', 
        maxWidth: 300, 
        minHeight: 150, 
        mb: 2, 
        transition: 'transform 0.3s, opacity 0.3s', // Transition effect
        '&:hover': { 
          transform: 'scale(1.05)', // Slight zoom effect on hover
          opacity: 0.9 
        }
      }}
    >
      <CardMedia component="img" sx={{ width: 80 }} image={image} alt={title} />
      <Box sx={{ width: 192 }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" paragraph>
            {description}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  );
};

const Steeps = () => {
  const steepsData = [
    { image: '/images/steeps/1.png', title: 'Indiquez le montant et la devise souhaités', description: 'Indiquez le montant et la devise souhaités' },
    { image: '/images/steeps/2.png', title: 'Indiquez le bureau de récupération', description: 'Indiquez le bureau de récupération' },
    { image: '/images/steeps/3.png', title: 'Récupérez votre devise', description: 'Récupérez votre devise' },
  ];

  return (
    <Container>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ pt: { xs: 2, sm: 4 }, pb: { xs: 2, sm: 4 }, justifyContent: 'center' }}>
        {steepsData.map((step, index) => (
          <React.Fragment key={index}>
            <Steep image={step.image} title={step.title} description={step.description} />
            {index < steepsData.length - 1 && (
              <Box 
                sx={{ 
                  display: { xs: "none", sm: "flex" }, 
                  flexDirection: "column", 
                  justifyContent: "center", 
                  transition: 'transform 0.3s, opacity 0.3s', // Transition for icon
                  '&:hover': { 
                    transform: 'rotate(15deg)', // Rotate effect on hover
                    opacity: 0.7 
                  }
                }}
              >
                <ForwardIcon />
              </Box>
            )}
          </React.Fragment>
        ))}
      </Stack>
    </Container>
  );
};

export default Steeps;
