import React,{useEffect} from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ReplyIcon from "@mui/icons-material/Reply";

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import dayjs from "dayjs";





import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  Paper,
  Divider,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useGetAllContactsQuery } from "../../app/services/contactApi";
import Loader from "../loader/Loader";const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

const Contact = () => {
  const { data: messages, error, isLoading } = useGetAllContactsQuery();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));
  useEffect(() => {
    // Remettre le défilement en haut de la page à chaque chargement
    window.scrollTo(0, 0);
}, []);

  console.log("Messages:", messages); // Vérifiez les données récupérées dans la console
  console.log("Error:", error); // Vérifiez s'il y a des erreurs dans la console
  console.log("Loading:", isLoading); // Vérifiez le statut de chargement dans la console

  if (isLoading) return <Loader />;
  if (error) return <div>Error: {error.message}</div>;

  return (






    
    <Container
      maxWidth="xl"
      sx={{
        backgroundColor: "#ffff8d",
        pt: 0,
        pb: 2,
        marginTop: 0,
        // padding: 4,
      }}
    >
      <Typography variant="h5">Gestion des Messages</Typography>
      <Typography paragraph>
        Dans cette section, vous pouvez gérer les messages reçus depuis le
        formulaire de contact.
      </Typography>

      

                {messages.map((message) => (
                  <Card sx={{ minWidth: 275, marginBottom:2, }} key={message.id}>
                  <CardContent>
                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                      Message envoyé le {dayjs(message.created_at).format('DD-MM-YYYY')} à {dayjs(message.created_at).format('HH:mm')}
                    </Typography>
                    <Typography variant="h5" component="div">
                    {message.name}
                    </Typography>
                    <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>{message.email}</Typography>
                    <Typography variant="body2">
                    {message.message}
                      <br />
                      <Divider/>
                      
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small"><a
                        href={`mailto:${message.email}?subject=${encodeURIComponent("Exchange.com: Réponse à votre message")}`}
                      >
                         <ReplyIcon />Répondre
                      </a></Button>
                  </CardActions>
                </Card>
                ))}

    </Container>
  );
};

export default Contact;
