import * as React from "react";
import { Stack, Typography, Box, IconButton, Badge } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LanguageIcon from "@mui/icons-material/Language";
import { useAuth } from "../../contexts/AuthContext";
import { useGetUserNotificationsQuery } from "../../app/services/notificationApi"; // Assurez-vous que le chemin est correct
import ModalDialog from "./ModalDialog";

export default function NotificationsBar() {
  const { user } = useAuth();

  const { data: notifications, error, isLoading } = useGetUserNotificationsQuery(1);
  const unreadNotificationsCount = notifications?.length || 0;

  return (
    <Stack
      direction="row"
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        pl:2,
      }}
    >
      {/* Partie alignée à gauche */}
      <Box>
        <Typography variant="h5" noWrap component="div">
          Espace d'administration
        </Typography>
      </Box>

          <ModalDialog />
    </Stack>
  );
}
