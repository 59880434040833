import {createApi,fetchBaseQuery} from '@reduxjs/toolkit/query/react'
export const authApi=createApi({
    reducerPath:'authApi',
    baseQuery:fetchBaseQuery({
        // baseUrl:'http://localhost:8000/api',
        baseUrl: process.env.REACT_APP_BASE_URL,
        credentials: "include",
    }),
    tagTypes:["Auth"],
    keepUnusedDataFor:60,
    endpoints:(builder)=>({
        // getAllDevises:builder.query({
        //     query:()=>({url:'/',
            
        //     }),
        //     providesTags:['Devises']
        // }),
        // getDevise:builder.query({
        //     query:(id)=>({url:`/${id}`,    
     
        //     })
        // }),
        loginUser:builder.mutation({
            query:(body)=>({
                url:'/login',
                method:'POST',
                body
            }),
            // invalidatesTags:['Devises']
        }), 
        registerUser:builder.mutation({
            query:(body)=>({
                url:`/register`,
                method:'POST',
                body
            }),
            // invalidatesTags:['Devises']
        })                   
    })
})
export const {useLoginUserMutation,useRegisterUserMutation}=authApi;