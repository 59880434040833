import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { deviseApi } from "./services/deviseApi";
import { setupListeners } from "@reduxjs/toolkit/query";

import changeReducer from './services/changeSlice';
import reservationReducer from './services/reservationSlice'
import { reservationApi } from "./services/reservationApi";
import { bureauApi } from "./services/bureauApi";
import { authApi } from "./services/authApi";
import { newsApi } from "./services/newsApi";
import { faqsApi } from "./services/faqsApi";
import { contactApi } from "./services/contactApi";
import { notificationApi } from "./services/notificationApi";
export const store=configureStore({
    reducer:{
        change:changeReducer,
        reservation:reservationReducer,
        [deviseApi.reducerPath]:deviseApi.reducer,
        [reservationApi.reducerPath]:reservationApi.reducer,
        [bureauApi.reducerPath]:bureauApi.reducer,
        [authApi.reducerPath]:authApi.reducer,
        [newsApi.reducerPath]:newsApi.reducer,
        [faqsApi.reducerPath]:faqsApi.reducer,
        [contactApi.reducerPath]:contactApi.reducer,
        [notificationApi.reducerPath]:notificationApi.reducer,
    },
    middleware:(getDefaultMiddleware)=>getDefaultMiddleware().concat(deviseApi.middleware).concat(reservationApi.middleware).concat(bureauApi.middleware).concat(authApi.middleware).concat(newsApi.middleware).concat(faqsApi.middleware).concat(contactApi.middleware).concat(notificationApi.middleware)
})

setupListeners(store.dispatch)
