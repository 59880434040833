import React from "react";
import { Box, Link, Typography, Stack, Paper, Divider } from "@mui/material";

const LiensUtils = () => {
  const liens = [
    {
      name: "Dotation touristique",
      description:
        "Pour consulter à distance et en toute sécurité le solde de vos dotations personnelles en devises et visualiser l’historique des opérations d’achat de devises effectuées auprès des opérateurs de change",
      liens: [
        {
          textlien: "Télécharger l’application OC CONNECT ",
          url: "https://play.google.com/store/apps/details?id=com.officedeschanges.occonnect",
        },
        {
          textlien: "Télécharger le guide d’utilisation de l’application ",
          url: "https://www.oc.gov.ma/sites/default/files/Guide%20utilisateur%20OC%20CONNECT.pdf",
        },
      ],
    },
    {
      name: "Dotation touristique supplémentaire ",
      description: "Vous désirez bénéficier du supplément de la dotation touristique indexé sur l’IR ?",
      liens: [
        {
          textlien: "Pour accéder au service",
          url: "https://dts.oc.gov.ma/SGD_IR/",
        },
        {
          textlien: "Pour connaître les modalités pratiques",
          url: "https://www.oc.gov.ma/sites/default/files/documents/Guide%20DTS/Guide%20Service%20en%20ligne%20%20DTS%20grand%20public.pdf",
        },
      ],
    },
  ];

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "black", // Couleur de fond noire
        color: "white", // Texte en blanc
        padding: "20px 0",
        textAlign: "center",
        marginTop: "auto",
        //   borderRadius: { xs: '0', sm: '160px 0 0 0' }, // Arrondi du footer
        boxShadow: 3, // Ajouter une ombre pour plus de profondeur
      }}
    >
      <Typography variant="h4" sx={{ marginBottom: 4, color: "#ffdb02" }}>
        Liens utiles
      </Typography>
      {/* <Typography variant="body1" sx={{ marginBottom: 4 }}>
                Nous collaborons avec des partenaires de confiance pour vous offrir les meilleurs services d'échange. Découvrez nos partenaires ci-dessous.
            </Typography> */}
      <Stack
  direction={{ xs: "column", sm: "row" }} // Aligns vertically on small screens, horizontally on large screens
  justifyContent="center"
  alignItems={{ xs: "start", sm: "start" }} // Aligns items
  spacing={4} // Spacing between items
  sx={{ flexWrap: "wrap" }} // Allows items to wrap to the next line
>
  {liens.map((lien, index) => (
    <Stack
      key={index} // Add a unique key for each stack
      direction="column"
      justifyContent="start"
      alignItems="start"
      spacing={2}
      sx={{
        color: "#fffde7",
        backgroundColor: "black",
        maxWidth: 360,
        padding: 2, // Adds padding for better spacing
      }}
    >
      <Box>
        <Typography variant="h6">{lien.name}</Typography>
        <Divider sx={{ backgroundColor: "white" }} />
      </Box>
      <Box>
        <Typography variant="body2" sx={{ textAlign: 'left' }}>{lien.description}</Typography>
      </Box>
      
      {lien.liens.map((subLien, subIndex) => (
        <Link
          key={subIndex}
          href={subLien.url} // Use the actual URL from the object
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "#fffde7",
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
              color: "yellow", // White on hover
            },
          }}
        >
          <Typography variant="body2">{subLien.textlien}</Typography>
        </Link>
      ))}
    </Stack>
  ))}
</Stack>

    </Box>
  );
};

export default LiensUtils;
