import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { LooksOne, LooksTwo } from "@mui/icons-material";

function CalculatorSideVente() {
  return (
    <Box
      sx={{
        // width: "40%",
        display: "flex",
        flexDirection:"column",
        // justifyContent: "center",
        color: "black",
        backgroundColor:"yellow",
        borderRadius:2,
        padding:2
      }}
    >
        <Typography variant="h4">EXCHANGE.COM</Typography>
        <Typography variant="h5">les bureaux de change qui sont proches de vous</Typography>

    </Box>
  );
}

export default CalculatorSideVente;
