import React from "react";
import { Container, Typography, Link } from "@mui/material";

function PolitiqueConfidentialite() {
  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        flexDirection: "column",
        pt: { xs: 4, sm: 8 },
        pb: { xs: 4, sm: 8 },
        background: "white",
        marginBottom: { xs: 4, sm: 8 },
      }}
    >
      <Typography
        variant="h3"
        align="center"
        sx={{
          fontSize: { xs: 24, sm: 56 },
          color: "black",
          pb: { xs: 2, sm: 4 },
        }}
      >
        Politique de Confidentialité
      </Typography>
      <Typography variant="body1" paragraph>Date de la dirnière  mise à jour des Conditions Générales d'Utilisation: 29/06/2024</Typography>
      <Typography variant="body1" paragraph>
        <Link href="/conditions" underline="hover" color="blue">
        Cliquez ici pour consulter les conditions générales de réservation et d'utilisation du site
        </Link>

      </Typography>

      <Typography variant="body1" paragraph>
        Chez EXCHANGE.COM, nous accordons une grande importance à la
        confidentialité et à la sécurité des données de nos utilisateurs. Cette
        politique de confidentialité décrit comment nous collectons, utilisons,
        partageons et protégeons vos informations lorsque vous utilisez notre
        site web et nos services.
      </Typography>
      <Typography variant="h4" paragraph>
        <strong>Collecte des informations :</strong>
      </Typography>
      <Typography variant="body1" paragraph>
        Nous collectons les informations que vous nous fournissez directement
        lorsque vous créez un compte, remplissez des formulaires, passez des
        commandes ou communiquez avec nous. Cela peut inclure des informations
        personnelles telles que votre nom, adresse e-mail, numéro de téléphone,
        adresse de livraison, etc.
      </Typography>
      <Typography variant="h4" paragraph>
        <strong>Utilisation des informations :</strong>
      </Typography>
      <Typography variant="body1" paragraph>
        Les informations que nous collectons sont utilisées pour traiter vos
        réservations, personnaliser votre expérience utilisateur, vous envoyer des
        notifications importantes relatives à votre compte, et améliorer nos
        produits et services.
      </Typography>
      <Typography variant="h4" paragraph>
        <strong>Partage des informations :</strong>
      </Typography>
      <Typography variant="body1" paragraph>
        Nous ne partageons vos informations personnelles qu'avec des tiers dans
        les cas suivants : pour traiter vos réservations, pour répondre à des
        exigences légales, pour protéger nos droits et ceux de nos utilisateurs,
        et avec votre consentement explicite.
      </Typography>
      <Typography variant="h4" paragraph>
        <strong>Sécurité des informations :</strong>
      </Typography>
      <Typography variant="body1" paragraph>
        Nous mettons en place des mesures de sécurité techniques et
        organisationnelles pour protéger vos informations contre tout accès non
        autorisé, utilisation abusive ou altération.
      </Typography>
      <Typography variant="h4" paragraph>
        <strong>Vos droits :</strong>
      </Typography>
      <Typography variant="body1" paragraph>
        Vous avez le droit d'accéder à vos informations personnelles, de les
        corriger, de les supprimer ou de limiter leur traitement. Vous pouvez
        également vous opposer au traitement de vos informations dans certaines
        circonstances.
      </Typography>
      <Typography variant="h4" paragraph>
        <strong>Acceptation des cookies :</strong>
      </Typography>
      <Typography variant="body1" paragraph>
        Notre site web utilise des cookies pour améliorer votre expérience
        utilisateur. En continuant à naviguer sur ce site, vous acceptez notre
        utilisation des cookies conformément à notre politique de
        confidentialité.
      </Typography>
      <Typography variant="body1" paragraph>
        Vous pouvez gérer vos préférences de cookies et en savoir plus sur notre
        utilisation des cookies en consultant notre{" "}
        <Link href="/politiquecookies" underline="hover" color="primary">
          Politique sur les cookies
        </Link>
        .
      </Typography>

      <Typography variant="h4" paragraph>
        <strong>Stockage des Données de Session :</strong>
      </Typography>
      <Typography variant="body1" paragraph>
        Nous utilisons des cookies et d'autres technologies de suivi pour
        stocker des informations sur votre appareil et naviguer sur notre site
        web. Ces informations sont utilisées pour assurer le bon fonctionnement
        de notre service et pour améliorer votre expérience utilisateur.{" "}
      </Typography>

      <Typography variant="h4" paragraph>
        <strong>Quelles données sont stockées ? :</strong>
      </Typography>
      <Typography variant="body1" paragraph>
        Nous stockons des informations telles que votre identifiant
        d'utilisateur, vos préférences de langue, et d'autres informations
        nécessaires pour maintenir votre session active.
      </Typography>
      <Typography variant="h4" paragraph>
        <strong>Comment ces données sont-elles utilisées ? :</strong>
      </Typography>
      <Typography variant="body1" paragraph>
        Les données de session sont utilisées pour vous permettre de rester
        connecté(e) à notre service, pour personnaliser votre expérience
        utilisateur, et pour analyser l'utilisation de notre site web afin
        d'améliorer nos services.
      </Typography>
      <Typography variant="h4" paragraph>
        <strong>Vos choix :</strong>
      </Typography>
      <Typography variant="body1" paragraph>
        Vous avez le choix d'accepter ou de refuser l'utilisation de cookies et
        d'autres technologies de suivi via les paramètres de votre navigateur.
        Cependant, veuillez noter que certaines fonctionnalités de notre service
        peuvent ne pas fonctionner correctement si vous désactivez ces
        fonctionnalités.
      </Typography>


      <Typography variant="body1" paragraph>
      En utilisant notre site web sans modifier les paramètres de votre navigateur pour accepter les cookies, vous consentez à notre utilisation de cookies et d'autres technologies de suivi conformément à cette politique.
      </Typography>

      <Typography variant="body1" paragraph>
        En utilisant notre site web et nos services, vous consentez à notre
        politique de confidentialité et à nos pratiques en matière de collecte
        et de traitement des informations. Si vous avez des questions ou des
        préoccupations concernant cette politique, veuillez nous contacter à{" "}
        <Link href="mailto:contact@exchange-maroc.com">contact@exchange-maroc.com</Link>.
      </Typography>

    </Container>
  );
}

export default PolitiqueConfidentialite;
