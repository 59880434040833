import { Box, Container, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import React from "react";
import "./aboutus.css";
import BureauList from "../reservation/BureauList";
import { Helmet } from 'react-helmet-async';
import  { useEffect} from "react";
function Aboutus() {
useEffect(() => {
    // Remettre le défilement en haut de la page à chaque chargement
    window.scrollTo(0, 0);
}, []);
  return (
    <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      pt: { xs: 4, sm: 8 },
      pb: { xs: 2, sm: 4 },
      pl: { xs: 4, sm: 8 },
      pr: { xs: 4, sm: 8 },
      background:"#ffdb02",
    }}
    >
      <Helmet>
        <title>Qui sommes nous ? - EXCHANGE.COM</title>
        <meta
          name="description"
          content="EXCHANGE.COM propose des services fiables de change de devises à Marrakech et au Maroc. Échangez facilement des dirhams marocains (MAD) avec nous. Visitez notre site pour découvrir nos taux compétitifs et effectuer des transactions en toute simplicité."
        />
      </Helmet>
      <Typography
      variant="h3"
      align="center"
      sx={{
        fontSize: { xs: 24, sm: 56 },
        color: 'black',
        pb: { xs: 4, sm: 8 },
        animation: 'slideDown 0.8s ease-out',
        '@keyframes slideDown': {
          '0%': {
            opacity: 0,
            transform: 'translateY(-100px)',
          },
          '100%': {
            opacity: 1,
            transform: 'translateY(0)',
          },
        },
      }}
    >
        Vous servir dans les meilleurs conditions
        <Typography
          variant="h5"
          color="black"
          sx={{
            // pb: { xs: 2, sm: 4 },
            fontSize: { xs: 16, sm: 32 },
          }}
        >
          C'est notre première préocupation
        </Typography>
      </Typography>
      <Grid
        container
         rowSpacing={1}
        columnSpacing={{ sm: 1, md: 2 }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
      <Grid item xs={12}>
      <iframe
            style={{ width: "100%" }}
            src="https://www.google.com/maps/d/embed?mid=1FV8K69HUqrisaHF8siZTePbWKq8PpjA&ehbc=2E312F&noprof=1"
            // width="80vw"
            height="480"
          ></iframe>
      </Grid>

        <Grid item xs={12}>
          <Stack
            direction={"column"}
            spacing={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              pt: { xs: 2, sm: 4 },
              pb: { xs: 2, sm: 4 },
              borderRadius: 2,
            }}
          >
            <Typography variant="h4" color="black">
              Nos Bureaux de change
            </Typography>
            <Typography paragraph>
              Découvrez notre sélection des 6 sociétés de change de devises les
              mieux situées à Marrakech, vous offrant une proximité inégalée
              pour vos besoins de change. Nos partenaires se distinguent par un
              service d'assistance et des informations approfondies pour
              répondre à toutes vos questions. De plus, accumulez des points de
              fidélité à chaque réservation en ligne, vous offrant des avantages
              exclusifs lors de vos prochaines transactions. Faites confiance à
              nos partenaires pour une expérience de change de devises pratique,
              personnalisée et avantageuse à Marrakech.
            </Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} md={12}>
          <BureauList />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Aboutus;
