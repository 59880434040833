import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App2.js';
import reportWebVitals from './reportWebVitals';

import { ThemeProvider } from "@mui/material";
import  theme  from "./themes/theme";

import { Provider } from 'react-redux';
import {store} from './app/store.js'
import {BrowserRouter} from 'react-router-dom'
import App from './App.js';
import { AuthProvider } from './contexts/AuthContext.js';
import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
    <ThemeProvider theme={theme}>
    <Provider store={store}>
      <BrowserRouter>
      <HelmetProvider>
        <App />
      </HelmetProvider>
      </BrowserRouter>
 
      </Provider>
    </ThemeProvider>
    </AuthProvider>
    
   </React.StrictMode>
);
reportWebVitals();
