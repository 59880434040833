import React, { useState,useEffect } from 'react';
import { Box, Button, Container, Grid, InputLabel, Stack, TextField, Typography } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import { useAddContactMutation } from '../../app/services/contactApi';
import { Helmet } from 'react-helmet-async';

const Contact = () => {

  useEffect(() => {
    // Remettre le défilement en haut de la page à chaque chargement
    window.scrollTo(0, 0);
}, []);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [isSuccessAlert, setIsSuccessAlert] = useState(false);
  const [isErrorAlert, setIsErrorAlert] = useState(false);

  const [addContact, { isLoading }] = useAddContactMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addContact({ name, email, message });
      setAlertMessage('Message envoyé avec succès!');
      setIsSuccessAlert(true);
      setIsErrorAlert(false);
      // Réinitialiser les champs après un envoi réussi si nécessaire
      setName('');
      setEmail('');
      setMessage('');
    } catch (error) {
      console.error('Erreur lors de l\'envoi du message :', error);
      setAlertMessage('Erreur lors de l\'envoi du message.');
      setIsErrorAlert(true);
      setIsSuccessAlert(false);
    }
  };

  const handleAlertClose = () => {
    setIsSuccessAlert(false);
    setIsErrorAlert(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width:'100vw',
        pt: { xs: 4, sm: 8 },
        pb: { xs: 2, sm: 4 },
        pl: { xs: 4, sm: 8 },
        pr: { xs: 4, sm: 8 },
        mb:4,
        background: "#ffdb02",
      }}
    >
            <Helmet>
        <title>Contactez nous - EXCHANGE.COM</title>
        <meta
          name="description"
          content="EXCHANGE.COM propose des services fiables de change de devises à Marrakech et au Maroc. Échangez facilement des dirhams marocains (MAD) avec nous. Visitez notre site pour découvrir nos taux compétitifs et effectuer des transactions en toute simplicité."
        />
      </Helmet>
      <Typography
      variant="h3"
      align="center"
      sx={{
        fontSize: { xs: 24, sm: 56 },
        color: 'black',
        pb: { xs: 4, sm: 8 },
        animation: 'slideDown 0.8s ease-out',
        '@keyframes slideDown': {
          '0%': {
            opacity: 0,
            transform: 'translateY(-100px)',
          },
          '100%': {
            opacity: 1,
            transform: 'translateY(0)',
          },
        },
      }}
    >
        Pour toute information ou réclamation
      </Typography>

      <Grid
        container
        spacing={{ xs: 2, sm: 1, md: 2 }}
        direction="row"
        justifyContent="center" // Centrage horizontal pour tous les écrans
        alignItems="flex-start"
      >
        <Grid item xs={12} md={6}>
          <Stack
            direction={"column"}
            spacing={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", sm: "flex-start" }, // Centrer sur mobile, aligner à gauche sur les autres
              borderRadius: 2,
              p: { xs: 2, sm: 4 },
              textAlign: 'center', // Centrer le texte
            }}
          >
            <Typography variant="h5" align='center'>Contactez-nous</Typography>
            <Typography variant="body1" color='black' align='center'><EmailIcon /> Email: contact@exchange-maroc.com</Typography>
            <Typography variant="body1" color='black' align='center'><PhoneIcon /> Téléphone: (+212) 7 03 13 13 14</Typography>
            <Typography variant="h5" align='center'>Visitez-nous</Typography>
            <Typography variant="body1" color='black' align='center'><EmailIcon /> 22 Boulevard Zerktouni Gueliz Marrakech</Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={{
              border: 'solid',
              borderRadius: 2,
              p: { xs: 2, sm: 4 },
              textAlign: 'center', // Centrer le contenu dans cette boîte
              backgroundColor:'white',
            }}
          >
            <form onSubmit={handleSubmit}>
              <Stack
                direction={"column"}
                spacing={2}
                sx={{
                  display: "flex",
                  alignItems: "stretch",
                }}
              >
                <InputLabel id="name">Nom</InputLabel>
                <TextField
                  required
                  label="name"
                  id="name"
                  name="name"
                  size="small"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <InputLabel id="email">Email</InputLabel>
                <TextField
                  required
                  label="Email"
                  id="email"
                  size="small"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <InputLabel id="message">Message</InputLabel>
                <TextField
                  id="message"
                  label="Message"
                  required
                  multiline
                  maxRows={4}
                  variant="filled"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: "black",
                    color: "goldenrod",
                    mt: { xs: 1, sm: 2 },
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? 'Envoi en cours...' : 'Envoyer'}
                </Button>
              </Stack>
            </form>
            {isSuccessAlert && (
              <Typography variant="body1" color="success" sx={{ mt: 2 }}>
                {alertMessage}
                <Button onClick={handleAlertClose} color="inherit" size="small" sx={{ ml: 2 }}>
                  Fermer
                </Button>
              </Typography>
            )}
            {isErrorAlert && (
              <Typography variant="body1" color="error" sx={{ mt: 2 }}>
                {alertMessage}
                <Button onClick={handleAlertClose} color="inherit" size="small" sx={{ ml: 2 }}>
                  Fermer
                </Button>
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Contact;
