import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Badge,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Paper,
  Checkbox,
  Stack,
  Tooltip,
} from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useAuth } from "../../contexts/AuthContext";
import {
  useGetUserNotificationsQuery,
  useMarkNotificationAsReadMutation,
} from "../../app/services/notificationApi"; // Assurez-vous que le chemin est correct

export default function ModalDialog() {
  const { user } = useAuth();
  const [open, setOpen] = React.useState(false);
  const [selectedNotifications, setSelectedNotifications] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [notificationCount, setNotificationCount] = React.useState(0);
  const navigate = useNavigate();
  const {
    data: notifications,
    error,
    isLoading,
    refetch,
  } = useGetUserNotificationsQuery(1);
  const [markNotificationAsRead] = useMarkNotificationAsReadMutation();

  React.useEffect(() => {
    if (notifications) {
      setNotificationCount(notifications.length);
    }
  }, [notifications]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      refetch(); // Re-fetch notifications periodically
    }, 5000); // Check every 5 seconds

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [refetch]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCheckboxChange = (id) => {
    setSelectedNotifications((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((notificationId) => notificationId !== id)
        : [...prevSelected, id]
    );
  };

  const gestionreservation = () => {
    setOpen(false);
    navigate("/admin/gestionreservation");
  };
  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedNotifications([]);
    } else {
      const allIds = notifications.map((notification) => notification.id);
      setSelectedNotifications(allIds);
    }
    setSelectAll(!selectAll);
  };

  const markAsRead = async () => {
    try {
      await markNotificationAsRead(selectedNotifications).unwrap();
      console.log("Notifications marquées comme lues :", selectedNotifications);
      setSelectedNotifications([]);
      setSelectAll(false);
      handleClose();
    } catch (error) {
      console.error(
        "Erreur lors de la marque comme lue des notifications :",
        error
      );
    }
  };

  return (
    <>
      {user && user.user && user.user.role === "admin" && (
        <Box>
          <Tooltip title="Show 4 new mails">
            <IconButton
              size="large"
              aria-label="show 4 new mails"
              color="inherit"
            >
              <Badge badgeContent={4} color="error">
                <MailIcon />
              </Badge>
            </IconButton>
          </Tooltip>
          <Tooltip title={`Show ${notificationCount} new notifications`}>
            <IconButton
              size="large"
              aria-label={`show ${notificationCount} new notifications`}
              color="inherit"
              onClick={handleClickOpen}
            >
              <Badge badgeContent={notificationCount} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Tooltip>
        </Box>
      )}
      <Drawer anchor="right" open={open} onClose={handleClose}>
        <Box sx={{ width: 800 }}>
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Notifications des réservations
          </DialogTitle>
          <Typography variant="body2" align="center">
          <Stack direction={"row"} align="left" spacing={2} sx={{pt:1,pb:1,pl:4}}>
          <Box sx={{ width: 64, backgroundColor: '#ff9800' }}></Box>
            <Typography variant="body2">Couleur des réservations qui dépassent 100 000,00 MAD</Typography>
          </Stack>
          <Stack direction={"row"} align="left" spacing={2} sx={{pt:1,pb:1,pl:4}}>
            <Box sx={{width:64, backgroundColor:'#ffeb3b'}}></Box>
            <Typography variant="body2">Couleur des réservations qui dépassent 50 000,00 MAD</Typography>
          </Stack>
 
  </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Typography gutterBottom>
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >

                  <TableHead>
                    
                    <TableRow>
                      <TableCell>
                        <Stack direction={"row"} align="left">
                          <Tooltip title="Marquer comme lues">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={markAsRead}
                            >
                              <MarkEmailReadOutlinedIcon />
                            </IconButton>
                          </Tooltip>

                        </Stack>
                      </TableCell>
                      
                    </TableRow>
                    <TableRow sx={{backgroundColor:'black'}}>
                      <TableCell>
                        <Tooltip title="Sélectionner tout">
                          <Checkbox
                            checked={selectAll}
                            onChange={handleSelectAllChange}
                            sx={{ fontWeight: 'bold',color:'yellow'}}
                          />
                        </Tooltip>
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold',color:'yellow' }}>Transaction</TableCell>
                      <TableCell align="left" sx={{ fontWeight: 'bold',color:'yellow'}}>Montant</TableCell>
                      <TableCell align="left" sx={{ fontWeight: 'bold',color:'yellow' }}>Devise</TableCell>
                      <TableCell align="left" sx={{ fontWeight: 'bold',color:'yellow' }}>MAD</TableCell>
                      <TableCell align="left" sx={{ fontWeight: 'bold',color:'yellow' }}>Client</TableCell>
                      <TableCell align="left" sx={{ fontWeight: 'bold',color:'yellow' }}>Téléphone</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading ? (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          <Typography variant="body2">
                            Loading notifications...
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : notifications && notifications.length > 0 ? (
                      notifications.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            marginLeft:4,
                            marginRight:4,
                            backgroundColor: 
                            row.data.typeOperation === "achat"
                                ? row.data.MontantToReceive > 100000
                                  ? "#ff9800"
                                  : row.data.MontantToReceive > 50000
                                  ? "#ffeb3b"
                                  : "white"
                                : row.data.montantToExchange > 100000
                                ? "#ff9800"
                                : row.data.montantToExchange > 50000
                                ? "#ffeb3b"
                                : "white",
                          }}
                        >
                          <TableCell>
                            <Checkbox
                              checked={selectedNotifications.includes(row.id)}
                              onChange={() => handleCheckboxChange(row.id)}
                            />
                          </TableCell>
                          <TableCell align="left">
                            {row.data.typeOperation}
                          </TableCell>
                          <TableCell align="left">
                            {row.data.typeOperation === "achat"
                              ? row.data.montantToExchange
                              : row.data.MontantToReceive}
                          </TableCell>
                          <TableCell align="left">{row.data.devise}</TableCell>
                          <TableCell align="left">
                            {row.data.typeOperation === "achat"
                              ? row.data.MontantToReceive
                              : row.data.montantToExchange}
                          </TableCell>
                          <TableCell align="left">
                            {row.data.nomFamille + " " + row.data.prenom}
                          </TableCell>
                          <TableCell align="left">
                            {row.data.indicatifTelephone + row.data.telephone}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          <Typography variant="body2">
                            No notifications
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Typography>
          </DialogContent>
          {/* <DialogActions>
            <Button autoFocus onClick={gestionreservation}>
              Gestion des réservations
            </Button>
          </DialogActions> */}
        </Box>
      </Drawer>
    </>
  );
}
