import {
  Box,
  Button,
  Container,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Steeper from "./Steeper";
import Recap from "./Recap";
import { Link, Outlet, useNavigate } from "react-router-dom";
import RetraitInfo from "./RetraitInfo";
import Coordonnes from "./Coordonnes";
import { useSelector,useDispatch } from "react-redux";
import SteepReservation from "../home/SteepReservation";
import { Helmet } from 'react-helmet-async';

function Reservation() {

  const [steep, setSteep]=useState(1)
  const state = useSelector((state) => state.change.value);
  const navigate = useNavigate();
  useEffect(() => {
    // Remettre le défilement en haut de la page à chaque chargement
    window.scrollTo(0, 0);
}, []);



  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100vw",
        pt: { xs: 4, sm: 8 },
        pb: { xs: 4, sm: 8 },
        pl: { xs: 4, sm: 8 },
        pr: { xs: 4, sm: 8 },
        mb: 4,
        background: "#ffdb02",
      }}
    >
      <Helmet>
        <title>Réservation de devise - EXCHANGE.COM</title>
        <meta
          name="description"
          content="EXCHANGE.COM propose des services fiables de change de devises à Marrakech et au Maroc. Échangez facilement des dirhams marocains (MAD) avec nous. Visitez notre site pour découvrir nos taux compétitifs et effectuer des transactions en toute simplicité."
        />
      </Helmet>
      <Typography
      variant="h3"
      align="center"
      sx={{
        fontSize: { xs: 24, sm: 56 },
        color: 'black',
        pb: { xs: 4, sm: 8 },
        animation: 'slideDown 0.8s ease-out',
        '@keyframes slideDown': {
          '0%': {
            opacity: 0,
            transform: 'translateY(-100px)',
          },
          '100%': {
            opacity: 1,
            transform: 'translateY(0)',
          },
        },
      }}
    >
            Réservez votre argent en quelques clics.
          </Typography>
      <Box
        sx={{display: { xs: 'none', sm: 'flex' },
        backgroundColor: "white",
        width:'88vw',
        color: "goldenrod",
      
      }}
      ><Steeper steep={steep}/>
      </Box>
      
      <Box
        sx={{
          backgroundColor: "#ffee05",
          borderRadius: 2,
          width:{xs:'100vw',md:'88vw'},
          // padding: 4,
        }}
      >
        {/* <Typography variant='h3'
      align='center'
      sx={{
        fontSize: { xs: 16, sm: 24 },
        color:'black',
        // pb: { xs: 2, sm: 4 },
      }}
      >
        Bienvenue! Réservez votre argent en quelques clics.
      </Typography> */}

        <Box
          sx={{
            backgroundColor: "yellow",
            padding: 2,
            borderRadius: 2,
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap-reverse",
          }}
        >
          <Outlet />
        </Box> 
       <Typography variant='h3'
      // align='center'
      sx={{
        fontSize: { xs: 16, sm: 16 },
        color:'black',
         padding: { xs: 2, sm: 4 },
      }}
      ><strong>EXCHANGE.COM </strong>
        Société de change agréée par les autorités. Nous respectons rigoureusement toutes les lois en vigueur concernant la protection des données de nos clients. Votre confidentialité et sécurité sont notre priorité absolue.


      </Typography>       
      </Box>
      
    </Box>
  );
}

export default Reservation;
