import { useState, useRef } from 'react';
import { useCreateNewsMutation } from '../../app/services/newsApi';
import { Button, Container, Grid, Paper, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';
import Loader from '../loader/Loader';

const FormContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(2),
}));

function CreateNewsForm() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [texte, setTexte] = useState('');
  const [photo, setPhoto] = useState(null);
  const [photoError, setPhotoError] = useState('');
  const photoInputRef = useRef(null);
  const [createNews, { isLoading, isError, isSuccess }] = useCreateNewsMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('texte', texte);

    if (photo) {
      formData.append('photo', photo);
    }

    try {
      await createNews(formData);
      setTitle('');
      setDescription('');
      setTexte('');
      setPhoto(null);
      if (photoInputRef.current) {
        photoInputRef.current.value = ''; // Reset the file input
      }
    } catch (error) {
      console.error('Erreur lors de la création de l\'actualité :', error);
    }
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    const maxSize = 2 * 1024 * 1024; // 2MB

    if (file) {
      if (file.size > maxSize) {
        setPhotoError('La taille de la photo ne doit pas dépasser 2MB.');
        setPhoto(null);
        photoInputRef.current.value = '';
      } else {
        setPhotoError('');
        setPhoto(file);
      }
    }
  };

  return (
    <Container maxWidth="sm">
      <FormContainer component="form" onSubmit={handleSubmit}>
        {isLoading ? (
          <Loader />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" align="center" gutterBottom>Ajouter une actualité</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="text"
                label="Titre de l'actualité"
                variant="outlined"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Description de l'actualité"
                variant="outlined"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={6}
                label="Texte de l'actualité"
                variant="outlined"
                value={texte}
                onChange={(e) => setTexte(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <input
                accept=".jpg,.jpeg,.png,.gif"
                id="photo-upload"
                type="file"
                onChange={handlePhotoChange}
                ref={photoInputRef}
                style={{ display: 'none' }}
              />
              <label htmlFor="photo-upload">
                <Button variant="contained" component="span" color="primary">
                  Télécharger une photo
                </Button>
                {photo && <span style={{ marginLeft: '8px' }}>{photo.name}</span>}
              </label>
              {photoError && <Typography color="error">{photoError}</Typography>}
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
                Ajouter
              </Button>
            </Grid>
            {isError && (
              <Grid item xs={12}>
                <Typography color="error">Erreur lors de la création de l'actualité.</Typography>
              </Grid>
            )}
            {isSuccess && (
              <Grid item xs={12}>
                <Typography color="success">Actualité ajoutée avec succès.</Typography>
              </Grid>
            )}
          </Grid>
        )}
      </FormContainer>
    </Container>
  );
}

export default CreateNewsForm;
